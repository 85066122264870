import React from 'react';
import { Container } from '@mui/material';
import Loader from '../../../components/Common/Loader';
import { LandingLayout } from '../../../components/Layouts';
import FeaturedCourses from './FeaturedCourses/FeaturedCourses';
import { useQuery } from '@tanstack/react-query';
import { apiCms } from '../../../instances/axiosInstances';
import NumberOne from './NumberOne/NumberOne';
import { toCamelCase } from '../../../helpers/utils';
import TopStudents from './TopStudents/TopStudents';
import Companies from './Companies/Companies';
import { homeQuery } from './query';
import SuccessStories from '../../../components/Common/SuccessStories/SuccessStories';
import StudentJourney from './StudentJourney/StudentJourney';
import TopAdvantages from './TopAdvantages/TopAdvantages';
import WeeklyYouTube from './WeeklyYouTube/WeeklyYouTube';
import StartLearning from './StartLearning/StartLearning';
import TextMarquee from './TextMarquee';
import WebinarBanner from './WebinarBanner';
import Hero from './Hero';

const componentsMap = {
  featuredCourses: FeaturedCourses,
  weeklyYouTube: WeeklyYouTube,
  numberOne: NumberOne,
  topStudents: TopStudents,
  companies: Companies,
  successStories: SuccessStories,
  studentJourney: StudentJourney,
  topAdvantages: TopAdvantages,
  startLearning: StartLearning,
  textMarquee: TextMarquee,
  webinarBanner: WebinarBanner,
  hero: Hero,
  // Add additional components here
};

export default function Home() {
  const homeData = useQuery(['homeData'], () =>
    apiCms.get(`/home?${homeQuery}`)
  );

  if (homeData.isLoading) return <Loader />;

  const sections = homeData.data?.data?.data?.sections;

  if (!sections) return null;

  return (
    <LandingLayout>
      <Container maxWidth={false} disableGutters>
        {sections.map((section, index) => {
          const componentName = toCamelCase(section.__component);
          const Component = componentsMap[componentName];

          if (!Component) {
            console.warn(`Component "${componentName}" not found!`);
            return null;
          }

          return <Component key={`${section.id}-${index}`} {...section} />;
        })}
      </Container>
    </LandingLayout>
  );
}
