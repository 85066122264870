import { Box, Typography } from '@mui/material';
import ImagesMarquee from './ImagesMarquee';
import classes from './Companies.module.scss';

export default function Companies(props) {
  if (!props.images) return null;

  return (
    <Box className={classes.section} component='section'>
      <Typography component='h2' className={classes.title}>
        {props.title}
      </Typography>
      <Box className={classes.carousel}>
        <ImagesMarquee props={props.images} />
      </Box>
    </Box>
  );
}
