import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

export default function PaymentProvider({ clientSecret, children }) {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: "flat",
          //   variables: stripeFormStyles,
          //   rules: stripeFormRulesStyles,
        },
      }}
    >
      {children}
    </Elements>
  );
}
