import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function ModalHeader({ title, handleClose }) {
  return (
    <Box className={classes.header}>
      <Typography className={classes.title} component={"h2"}>
        {title}
      </Typography>
      <button className={classes.close} onClick={handleClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M6.2252 19.925L4.5752 18.275L10.3502 12.5L4.5752 6.72501L6.2252 5.07501L12.0002 10.85L17.7752 5.07501L19.4252 6.72501L13.6502 12.5L19.4252 18.275L17.7752 19.925L12.0002 14.15L6.2252 19.925Z"
            fill="#141416"
          />
        </svg>
      </button>
    </Box>
  );
}
