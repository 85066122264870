import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import Loader from '../../../components/Common/Loader';
import { AccentButton } from '../../../components/MUI/buttons/button';
import classes from './index.module.scss';

export default function PaymentForm({ handleRefresh }) {
  const { user } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setIsProcessing(true);

    try {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: { return_url: '' },
        redirect: 'if_required',
      });

      if (error) {
        console.error('Error confirming setup:', error.message);
        return;
      }

      // Call the success handler to trigger Stripe reset
      handleRefresh();
    } catch (err) {
      console.error('Submission Error:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {isProcessing ? <Loader type='section' /> : null}
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              email: user?.email,
            },
          },
          layout: 'tabs',
        }}
      />
      <AccentButton
        disabled={isProcessing}
        type='submit'
        variant='contained'
        fullWidth
      >
        Save
      </AccentButton>
    </form>
  );
}
