import {
  AccentButton,
  RedButton,
  WhiteButton,
} from '../../components/MUI/buttons/button';
import { Box, Typography } from '@mui/material';
import classes from './index.module.scss';
import SettingsLayout from '../../components/Layouts/SettingsLayout';
import SectionCard from '../../components/Common/SectionCard';
import Modal from '../../components/Common/Modal';
import { useContext, useRef, useState } from 'react';
import useModal from '../../components/Common/Modal/useModal';
import CancelDowngradeModal from './CancelDowngradeModal';
import CancelSubscirptionModal from './CancelSubscriptionModal';
import KeepSubscriptionModal from './KeepSubscriptionModal/KeepSubscriptionModal';
import InvoicesModal from './InvoicesModal';
import { SubscriptionsContext } from '../../context/SubscriptionsContext';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../helpers/formatCurrency';
import InfoBlock from '../../components/Common/InfoBlock';
import { AuthContext } from '../../context/AuthContext';
import { formatDate } from '../../helpers/formatDateTime';
import PaymentProvider from './PaymentProvider';
import PaymentForm from './PaymentForm';

export default function Subscriptions() {
  const { currentSubscription } = useContext(SubscriptionsContext);
  const { user } = useContext(AuthContext);

  const {
    plan,
    downgradePlan,
    cancelledAt,
    startsAt,
    nextBillingAt,
    // lastBillingAt,
  } = currentSubscription || {};

  const planName = plan?.name || 'Starter';

  const cancelDonwgradeModalRef = useRef();
  const cancelDowngradeModal = useModal(cancelDonwgradeModalRef);

  const cancelSubscriptionModalRef = useRef();
  const cancelSubscriptionModal = useModal(cancelSubscriptionModalRef);

  const keepSubscriptionModalRef = useRef();
  const keepSubscriptionModal = useModal(keepSubscriptionModalRef);

  const invoicesModalRef = useRef();
  const invoicesModal = useModal(invoicesModalRef);

  const navigtate = useNavigate();

  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Force payment form re-render
  const handleRefresh = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  return (
    <>
      <Modal className={classes.modal} modalRef={cancelDonwgradeModalRef}>
        <CancelDowngradeModal handleClose={cancelDowngradeModal.handleClose} />
      </Modal>

      <Modal className={classes.modal} modalRef={cancelSubscriptionModalRef}>
        <CancelSubscirptionModal
          handleClose={cancelSubscriptionModal.handleClose}
        />
      </Modal>

      <Modal className={classes.modal} modalRef={keepSubscriptionModalRef}>
        <KeepSubscriptionModal
          handleClose={keepSubscriptionModal.handleClose}
        />
      </Modal>

      <Modal className={classes.invoicesModal} modalRef={invoicesModalRef}>
        <InvoicesModal handleClose={invoicesModal.handleClose} />
      </Modal>

      <SettingsLayout title='Profile'>
        <SectionCard title='Plan'>
          <Box className={classes.body}>
            <Box className={classes.info}>
              <Typography className={classes.info__text}>
                Subscription plan: <span>{planName}</span>
              </Typography>
              <Typography className={classes.info__text}>
                Subscription price:{' '}
                <span>{plan?.price ? formatCurrency(plan.price) : 'Free'}</span>
              </Typography>

              {startsAt && nextBillingAt ? (
                <Typography className={classes.info__text}>
                  Current billing period:{' '}
                  <span>
                    {formatDate(new Date(startsAt))} -{' '}
                    {formatDate(new Date(nextBillingAt))}
                  </span>
                </Typography>
              ) : null}

              {downgradePlan && !cancelledAt ? (
                <>
                  <Box className={classes.info__messageBox}>
                    <InfoBlock>
                      Downgrade changes will be applied starting next billing
                      period. Until then, you can continue using current plan
                      features.
                    </InfoBlock>
                  </Box>
                  <Box>
                    <WhiteButton
                      isBig={false}
                      variant='contained'
                      onClick={cancelDowngradeModal.handleOpen}
                    >
                      Cancel downgrade request
                    </WhiteButton>
                  </Box>
                </>
              ) : null}

              {cancelledAt ? (
                <>
                  <Box className={classes.info__messageBox}>
                    <InfoBlock>
                      Your subscription will be canceled starting next billing
                      period. Until then, you can continue using current plan
                      features.
                    </InfoBlock>
                  </Box>
                  <Box>
                    <WhiteButton
                      isBig={false}
                      variant='contained'
                      onClick={keepSubscriptionModal.handleOpen}
                    >
                      Keep subscription
                    </WhiteButton>
                  </Box>
                </>
              ) : null}
            </Box>

            <Box className={classes.actions}>
              <AccentButton
                isBig={false}
                variant='contained'
                onClick={() => navigtate('/subscriptions')}
              >
                Change plan
              </AccentButton>
              {cancelledAt || planName === 'Starter' ? null : (
                <RedButton
                  isBig={false}
                  variant='outlined'
                  onClick={cancelSubscriptionModal.handleOpen}
                >
                  Cancel subscription
                </RedButton>
              )}
            </Box>
          </Box>
        </SectionCard>

        <SectionCard title='Billing'>
          <Box className={classes.body}>
            <Box className={classes.info}>
              <Typography className={classes.info__text}>
                Current billing email: <span>{user?.email}</span>
              </Typography>
              <Typography className={classes.info_description}>
                Invoices and receipts will be sent to this email
              </Typography>
              <Box>
                <WhiteButton
                  isBig={false}
                  variant='contained'
                  onClick={invoicesModal.handleOpen}
                >
                  Billing history
                </WhiteButton>
              </Box>
            </Box>
          </Box>
        </SectionCard>

        <SectionCard title='Payment'>
          <PaymentProvider refreshTrigger={refreshTrigger}>
            <PaymentForm handleRefresh={handleRefresh} />
          </PaymentProvider>
        </SectionCard>
      </SettingsLayout>
    </>
  );
}
