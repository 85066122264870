import { Box, Container, Typography } from "@mui/material";
import { PlatformLayout } from "../../../components/Layouts";
import { NavLink, Outlet } from "react-router-dom";
import classes from "./index.module.scss";
import { useContext,  } from "react";
import { AuthContext } from '../../../context/AuthContext';

export default function Courses() {
  const { auth } = useContext(AuthContext);
  return (
    <PlatformLayout>
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.navigation}>
          <Typography
            to={"/courses"}
            className={classes.title}
            variant={"h2"}
            component={NavLink}
            end
          >
            All Courses
          </Typography>
          {auth ? (
            <Typography
              to={"my-courses"}
              className={classes.title}
              variant={"h2"}
              component={NavLink}
              end
            >
              My Courses
            </Typography>
          ) : null}
        </Box>

        <Outlet />
      </Container>
    </PlatformLayout>
  );
}
