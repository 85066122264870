import React, { useCallback, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal as MUIModal, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import InfoBlock from "../../Common/InfoBlock";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import Loader from "../../Common/Loader";
import RadioLabel from "./RadioLabel";
import RadioBtn from "./RadioBtn";
import classes from "./index.module.scss";
import { formatCurrency } from "../../../helpers/formatCurrency";
import PaymentProvider from "./PaymentProvider";
import PaymentForm from "./PaymentForm";
import { SubscriptionsContext } from "../../../context/SubscriptionsContext";
import DowngradeSubscriptionModal from "./DowngradeSubscriptionModal";
import Modal from "../../Common/Modal";
import CouponForm from "./CouponForm";

const CustomModal = styled(MUIModal)(() => ({
  "&.MuiModal-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(255, 255, 255, 0.57)",
  },
}));

export default function BuySubscriptionModal({ plans }) {
  const { user, auth } = useContext(AuthContext);
  const {
    currentSubscription,
    selectedSubscription,
    setSelectedSubscription,
    downgradePopupRef,
    handleDowngradePopupOpen,
    handleDowngradePopupClose,
    isCheckoutPopupOpen,
    setIsCheckoutPopupOpen,
    redirectPath,
    setRedirectPath,
    courseRedirectPath,
    setCourseRedirectPath,
    selectedCourseId,
    setSelectedCourseId,
  } = useContext(SubscriptionsContext);

  const navigate = useNavigate();

  const [isCheckoutOpen, setCheckoutOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(undefined);
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState(undefined);
  const [prorate, setProrate] = useState(undefined);
  const [isPaymentProviderOpen, setIsPaymentProviderOpen] = useState(false);
  const [isLoadingPromo, setIsLoadingPromo] = useState(false);
  const [promotingPrice, setPromotingPrice] = useState(undefined);

  const handleCheckoutOpen = (e) => {
    e?.preventDefault();
    setIsCheckoutPopupOpen(false);
    setCheckoutOpen(true);
  };

  const handleCheckoutClose = useCallback(
    (e) => {
      e?.preventDefault();
      setIsCheckoutPopupOpen(false);
      setCheckoutOpen(false);
      setSelectedSubscription(null);
      setRedirectPath(undefined);
      setClientSecret(undefined);
      setStripeSubscriptionId(undefined);
      setProrate(undefined);
      setPromotingPrice(undefined);
    },
    [setIsCheckoutPopupOpen, setRedirectPath, setSelectedSubscription]
  );

  const cancelDowngradePopup = () => {
    setIsCheckoutPopupOpen(false);
    setSelectedSubscription(null);
    setClientSecret(undefined);
    setStripeSubscriptionId(undefined);
    setProrate(undefined);
    setPromotingPrice(undefined);
    handleDowngradePopupClose();
  };
  const approveDowngradePopup = () => {
    setIsCheckoutPopupOpen(false);
    handleDowngradePopupClose();
    handleCheckoutOpen();
  };

  const { mutate: getProrate, isLoading: isLoadingProrate } = useMutation(
    () => api.get(`/subscriptions/stripe/prorate`),
    {
      cacheTime: 0,
      onSuccess: ({ data }) => setProrate(data.list),
    }
  );

  const { mutateAsync: getClientSecret, isLoading: isLoadingClientSecret } =
    useMutation({
      mutationKey: ["clientSecret", selectedSubscription?.id],
      mutationFn: ({ path, id }) => {
        setIsPaymentProviderOpen(false);
        return api.post(path, {
          subscriptionPlanId: id,
        });
      },
      cacheTime: Infinity,
      onSuccess: ({ data }) => {
        setClientSecret(data.clientSecret);
        setStripeSubscriptionId(data.stripeSubscriptionId);
        setIsPaymentProviderOpen(true);
      },
      onError: (e) => console.log(e),
    });

  const handleChange = async (event) => {
    const newSelectedSubscription = (
      !currentSubscription?.plan.isFree ? prorate : plans.data
    )?.find((subscription) => subscription.id === Number(event.target.value));
    if (currentSubscription?.plan.isFree && clientSecret) {
      setPromotingPrice(undefined);
      await getClientSecret({
        path: "/subscriptions/stripe/payment-intent",
        id: newSelectedSubscription.id,
      });
    }
    setSelectedSubscription(newSelectedSubscription);
  };

  useEffect(() => {
    if (
      isCheckoutOpen &&
      currentSubscription &&
      selectedSubscription &&
      !clientSecret
    ) {
      const path = currentSubscription?.plan.isFree
        ? "/subscriptions/stripe/payment-intent"
        : "/subscriptions/stripe/setup-intent";
      const id = selectedSubscription.id;
      getClientSecret({ path, id });
    }

    if (
      isCheckoutPopupOpen &&
      currentSubscription &&
      !currentSubscription?.plan.isFree &&
      !prorate
    ) {
      getProrate();
    }
  }, [isCheckoutPopupOpen, isCheckoutOpen]);

  useEffect(() => {
    if (
      isCheckoutPopupOpen &&
      selectedSubscription &&
      (prorate || currentSubscription?.plan.isFree)
    ) {
      const isDowngrade = !currentSubscription?.plan.isFree
        ? prorate.find(
            (subscription) => subscription.id === selectedSubscription.id
          ).isDowngrade
        : false;
      isDowngrade ? handleDowngradePopupOpen() : handleCheckoutOpen();
    }
  }, [prorate, handleDowngradePopupOpen, isCheckoutPopupOpen]);

  useEffect(() => {
    if (!!prorate && !!selectedSubscription) {
      const newSelectedSubscription = prorate.find(
        (subscription) => subscription.id === selectedSubscription.id
      );
      setSelectedSubscription(newSelectedSubscription);
    }
  }, [prorate, selectedSubscription, setSelectedSubscription]);

  useEffect(() => {
    auth &&
      redirectPath &&
      selectedSubscription &&
      currentSubscription &&
      selectedSubscription.id !== currentSubscription.plan.id &&
      setIsCheckoutPopupOpen(true);
  }, [
    auth,
    redirectPath,
    selectedSubscription,
    setIsCheckoutPopupOpen,
    currentSubscription,
  ]);

  const invalidateCourse = useCallback(() => {
    navigate(courseRedirectPath);
    setCourseRedirectPath(undefined);
    setSelectedCourseId(undefined);
  }, [
    navigate,
    courseRedirectPath,
    setCourseRedirectPath,
    setSelectedCourseId,
  ]);

  useEffect(() => {
    if (
      auth &&
      courseRedirectPath &&
      currentSubscription &&
      plans &&
      selectedCourseId
    ) {
      const currentSubscriptionCourses = plans.data.find(
        (plan) => plan.id === currentSubscription.plan.id
      ).courses;
      const isCourseRedirect = currentSubscriptionCourses.some(
        (course) => course?.course?.id === Number(selectedCourseId)
      );
      if (
        isCourseRedirect &&
        currentSubscription.plan.id === selectedSubscription?.id
      ) {
        invalidateCourse();
      }
    }
  }, [
    auth,
    courseRedirectPath,
    currentSubscription,
    invalidateCourse,
    navigate,
    plans,
    selectedCourseId,
    selectedSubscription,
    setCourseRedirectPath,
    setSelectedCourseId,
  ]);

  const selectedSubscriptionPrice = selectedSubscription?.price;
  const selectedSubscriptionProrateTotal = selectedSubscription?.prorateTotal;
  const isUpgrade = selectedSubscription?.isUpgrade;
  const isDowngrade = selectedSubscription?.isDowngrade;
  return (
    <>
      {isLoadingProrate || isLoadingClientSecret || isLoadingPromo ? (
        <Loader />
      ) : null}
      <Modal modalRef={downgradePopupRef} className={classes.modal}>
        <DowngradeSubscriptionModal
          cancelDowngradePopup={cancelDowngradePopup}
          approveDowngradePopup={approveDowngradePopup}
        />
      </Modal>

      <CustomModal
        open={isCheckoutOpen}
        onClose={handleCheckoutClose}
        aria-labelledby="modal-payment-Modal"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={classes.body}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isLoadingClientSecret || isLoadingProrate ? <Loader /> : null}
          <Box className={classes.header}>
            <Typography
              className={classes.header__title}
              variant="h2"
              component="h2"
            >
              Checkout
            </Typography>
            <button
              aria-labelledby="close modal"
              onClick={handleCheckoutClose}
              className={classes.header__close}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M6.54673 20.1348L4.89673 18.4848L10.6717 12.7098L4.89673 6.93482L6.54673 5.28482L12.3217 11.0598L18.0967 5.28482L19.7467 6.93482L13.9717 12.7098L19.7467 18.4848L18.0967 20.1348L12.3217 14.3598L6.54673 20.1348Z"
                  fill="#3D40D5"
                />
              </svg>
            </button>
          </Box>
          <Box className={classes.wrapper}>
            <Box className={classes.course}>
              <Box className={classes.course__data}>
                <Typography
                  className={classes.total}
                  variant="h3"
                  component="h3"
                >
                  Subscription
                </Typography>
                <FormControl>
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={selectedSubscription?.id}
                    onChange={handleChange}
                    className={classes.plans}
                  >
                    {(!currentSubscription?.plan.isFree
                      ? prorate
                      : plans?.data
                    )?.map((plan) => {
                      if (plan.isFree) {
                        return null;
                      }
                      return (
                        <RadioBtn
                          key={plan.id}
                          value={plan.id}
                          disabled={currentSubscription?.plan.id === plan.id}
                        >
                          <RadioLabel
                            name={plan.name}
                            price={plan.price}
                            originalPrice={plan.originalPrice}
                            period={plan.period}
                          />
                        </RadioBtn>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {selectedSubscription?.isUpgrade ? (
                  <InfoBlock>
                    Upgrade now for immediate access to new features with{" "}
                    <span className={classes.lineThrough}>
                      {formatCurrency(selectedSubscription.price)}
                    </span>{" "}
                    an additional{" "}
                    <span>
                      {formatCurrency(selectedSubscription.prorateTotal)}
                    </span>{" "}
                    charge for the current period. Charges will be pro-rated.
                  </InfoBlock>
                ) : null}
              </Box>
              {selectedSubscription && !isUpgrade && !isDowngrade ? (
                <div className={classes.coupon}>
                  <CouponForm
                    subscriptionPlanId={selectedSubscription?.id}
                    setClientSecret={setClientSecret}
                    setStripeSubscriptionId={setStripeSubscriptionId}
                    stripeSubscriptionId={stripeSubscriptionId}
                    setIsPaymentProviderOpen={setIsPaymentProviderOpen}
                    setIsLoadingPromo={setIsLoadingPromo}
                    setPromotingPrice={setPromotingPrice}
                    promotingPrice={promotingPrice}
                    isUpgrade={selectedSubscription?.isUpgrade}
                  />
                </div>
              ) : null}
              <div className={classes.totalBlock}>
                {promotingPrice ? (
                  <>
                    <Box className={classes.course__price}>
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.total}
                      >
                        Price
                      </Typography>
                      <Typography className={classes.course__title}>
                        {formatCurrency(
                          selectedSubscriptionProrateTotal ||
                            selectedSubscriptionPrice
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.course__price}>
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.total}
                      >
                        Discount
                      </Typography>
                      <Typography className={classes.course__title}>
                        -{formatCurrency(
                          (selectedSubscriptionProrateTotal ||
                            selectedSubscriptionPrice) - promotingPrice
                        )}
                      </Typography>
                    </Box>
                  </>
                ) : null}
                <Box className={classes.course__price}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.total}
                  >
                    Order Total
                  </Typography>
                  <Typography className={classes.course__title}>
                    {formatCurrency(
                      promotingPrice ||
                        selectedSubscriptionProrateTotal ||
                        selectedSubscriptionPrice
                    )}
                  </Typography>
                </Box>
              </div>
            </Box>
            <Box className={classes.content}>
              {user?.email ||
              JSON.parse(localStorage.getItem("user"))?.email ? (
                <Typography className={classes.user}>
                  {user?.email ||
                    JSON.parse(localStorage.getItem("user"))?.email}
                </Typography>
              ) : null}

              <Box className={classes.payment}>
                {clientSecret && isPaymentProviderOpen ? (
                  <PaymentProvider clientSecret={clientSecret}>
                    <PaymentForm
                      intentType={
                        currentSubscription?.plan.isFree ? "payment" : "setup"
                      }
                      handleClose={handleCheckoutClose}
                      selectedSubscription={selectedSubscription}
                      courseRedirectPath={courseRedirectPath}
                      setCourseRedirectPath={setCourseRedirectPath}
                    />
                  </PaymentProvider>
                ) : null}
                <Typography className={classes.terms}>
                  by paying you agree to the{" "}
                  <Typography
                    className={classes.terms__link}
                    component={Link}
                    target={"_blank"}
                    to="/terms-and-conditions"
                  >
                    Terms & Conditions
                  </Typography>
                </Typography>
              </Box>

              <InfoBlock>
                Your security is important to us. We do not store your credit
                card information.
              </InfoBlock>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
