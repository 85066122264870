import { Box, Container, Typography } from '@mui/material';
import classes from './NumberOne.module.scss';
import { AccentButton } from '../../../../components/MUI/buttons/button';
import TextHighlight from '../../../../components/Common/TextHighlight/TextHighlight';
import { Link } from 'react-router-dom';

export default function NumberOne(props) {
  return (
    <Box className={classes.section} component='section'>
      <Container className={classes.container} maxWidth='lg'>
        <Box className={classes.blocks}>
          <Box className={classes.imageBox}>
            <img
              src={`${process.env.REACT_APP_CMS_DOMAIN}${props.image.url}`}
              alt={props.title || ''}
            />
          </Box>
          <Box className={classes.info}>
            <Typography component='h2' className={classes.title}>
              {props.title}
            </Typography>
            <Typography
              component='p'
              className={`${classes.description} ${classes.mob}`}
            >
              <TextHighlight text={props.description} />
            </Typography>
            <Typography
              component='p'
              className={`${classes.description} ${classes.desk}`}
            >
              <TextHighlight
                text={props.description}
                height='6px'
                bottom='6px'
              />
            </Typography>
            {props.items.map((item, index) => (
              <Box className={classes.listItem} key={index}>
                <img
                  className={classes.itemImage}
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.url}`}
                  alt={''}
                />
                <Box>
                  <Typography component='h3' className={classes.listTitle}>
                    {item.title}
                  </Typography>
                  <Typography component='p' className={classes.listDescription}>
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box className={classes.ctaTextBox}>
          <Typography component='p' className={classes.ctaText}>
            {props.ctaText}
          </Typography>
          <Typography
            component='p'
            className={`${classes.ctaText2} ${classes.mob}`}
          >
            <TextHighlight text={props.ctaText2} />
          </Typography>
          <Typography
            component='p'
            className={`${classes.ctaText2} ${classes.desk}`}
          >
            <TextHighlight text={props.ctaText2} height='5px' bottom='5px' />
          </Typography>
        </Box>

        <Box className={classes.actions}>
          <AccentButton
            isBig={false}
            component={Link}
            to={props.link.link}
            target={'_blank'}
            className={classes.ctaButton}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='25'
              viewBox='0 0 25 25'
              fill='none'
            >
              <path
                d='M5.5 22.7056C4.95 22.7056 4.47917 22.5097 4.0875 22.1181C3.69583 21.7264 3.5 21.2556 3.5 20.7056V6.70557C3.5 6.15557 3.69583 5.68473 4.0875 5.29307C4.47917 4.9014 4.95 4.70557 5.5 4.70557H6.5V2.70557H8.5V4.70557H16.5V2.70557H18.5V4.70557H19.5C20.05 4.70557 20.5208 4.9014 20.9125 5.29307C21.3042 5.68473 21.5 6.15557 21.5 6.70557V20.7056C21.5 21.2556 21.3042 21.7264 20.9125 22.1181C20.5208 22.5097 20.05 22.7056 19.5 22.7056H5.5ZM5.5 20.7056H19.5V10.7056H5.5V20.7056ZM5.5 8.70557H19.5V6.70557H5.5V8.70557Z'
                fill='white'
              />
            </svg>
            {props.link.text}
          </AccentButton>
        </Box>
      </Container>
    </Box>
  );
}
