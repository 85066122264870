import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";

import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../MUI/buttons/button";
import successToast from "../../../toasts/successToast";
import errorToast from "../../../toasts/errorToast";
import {  useKeepSubscriptionApi } from "../../../../apis/payments";
import { useQueryClient } from "@tanstack/react-query";

export default function KeepSubscriptionModal({ handlePopupClose }) {
  const queryClient = useQueryClient();
  const successToastId = "success-toast";
  const errorToastId = "error-toast";

  const keepSubscriptionApi = useKeepSubscriptionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["currentSubscription"],
        refetchType: "all",
      });
      handlePopupClose();
      successToast(
        "Subscription canceled. Plan active until next billing period",
        {
          toastId: successToastId,
        }
      );
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message, {
        toastId: errorToastId,
        autoClose: false,
        hideProgressBar: true,
      });
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader
        title={"Keep subscription?"}
        handleClose={handlePopupClose}
      />
      <Typography className={classes.text}>
        No additional charges will be made. You will remain on your current
        plan.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handlePopupClose}
          isBig={false}
          variant="contained"
          disabled={keepSubscriptionApi.isLoading}
        >
          Close
        </WhiteButton>
        <RedButton
          onClick={() => keepSubscriptionApi.mutate()}
          isBig={false}
          variant="contained"
          disabled={keepSubscriptionApi.isLoading}
        >
          Keep plan
        </RedButton>
      </Box>
    </Box>
  );
}
