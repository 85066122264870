import CtaButton from '../../CtaButton/CtaButton';
import Heading from '../../Heading/Heading';
import classes from './Watch.module.scss';

export default function Watch(props) {
  if (!props || !props.id) return null;

  const { title, link } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <Heading className={classes.heading}>{title}</Heading>
        <CtaButton href={link.link}>{link.text}</CtaButton>
      </div>
    </section>
  );
}
