import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  NotFound,
  PrivacyPolicy,
  TermsAndConditions,
} from "../pages/root";
import { ProtectedRoute, QuestionnaireRoute } from "./routes";
import { Courses, Paths } from "../pages/platform";
import CourseDetails from "../pages/courses/CourseDetails";
import {
  Login,
  CreatePassword,
  Registration,
  ResendPassword,
  ForgotPassword,
  ResendForgottenPassword,
  CreateForgottenPassword,
} from "../pages/auth";
import OngoingLecture from "../pages/courses/OngoingLecture";
import Lecture from "../pages/courses/OngoingLecture/Lecture";
import Questionnaire from "../pages/Questionnaire";

import { AuthLayout } from "../components/Layouts";
import Profile from "../pages/Profile";
import Test from "../pages/Test";
import AffiliateProgramConditions from "../pages/root/policies/AffiliateProgramConditions";
import PathsDetails from "../pages/paths/PathsDetails";
import Posts from "../pages/platform/Blog/Posts";
import Post from "../pages/platform/Blog/Post";
import Mentorship from "../pages/platform/Mentorship";
import Mentor from "../pages/platform/Mentorship/Mentor";
import Rating from "../pages/platform/Rating";
import StudentRating from "../pages/platform/Rating/StudentRating";
import UserRating from "../pages/platform/Rating/UserRating";
import CertificateDetails from "../pages/platform/Rating/CertificateDetails";
import AllCourses from "../pages/platform/Courses/AllCourses";
import MyCourses from "../pages/platform/Courses/MyCourses";
import Instructor from "../pages/platform/Instructor";
import ThankYou from "../pages/root/ThankYou";
import Events from "../pages/platform/Events";
import Subscriptions from '../pages/subscriptions';
import Security from '../pages/security';
import Plans from "../pages/plans/Plans";
import { useContext } from "react";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import Event from '../pages/platform/Event';

export const Router = ({ isAuthenticated, isAuthLoading, user }) => {
  const { redirectPath, courseRedirectPath } = useContext(SubscriptionsContext);
  const authElement = () => {
    const path =
      redirectPath ||
      courseRedirectPath ||
      localStorage.getItem("redirectPath") ||
      "/";
    return !isAuthenticated ? <AuthLayout /> : <Navigate to={path} />;
  };
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/thank-you/:slug" element={<ThankYou />}></Route>
      <Route
        path="/affiliate-program-conditions"
        element={<AffiliateProgramConditions />}
      ></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditions />}
      ></Route>
      <Route path="/discord-redirect" element={<Test />}></Route>
      {/* registration */}
      <Route path="/registration" element={authElement()}>
        <Route index={true} element={<Registration />} />
        <Route path="resend-password" element={<ResendPassword />} />
        <Route path="create-profile" element={<CreatePassword />} />
      </Route>
      {/* login */}
      <Route path="/login" element={authElement()}>
        <Route index={true} element={<Login />} />
      </Route>
      <Route path="/forgot-password" element={authElement()}>
        <Route index={true} element={<ForgotPassword />} />
      </Route>
      <Route path="/resend-password" element={authElement()}>
        <Route index={true} element={<ResendForgottenPassword />} />
      </Route>
      <Route path="/reset-password" element={authElement()}>
        <Route index={true} element={<CreateForgottenPassword />} />
      </Route>
      {/* courses and path */}
      <Route path="paths" element={<Paths />} />
      <Route path="paths/:id" element={<PathsDetails />} />

      <Route path="courses" element={<Courses />}>
        <Route
          index
          element={<AllCourses isAuthenticated={isAuthenticated} />}
        />
      </Route>

      <Route
        path="courses"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/registration"}
          >
            <Courses />
          </ProtectedRoute>
        }
      >
        <Route path="my-courses" element={<MyCourses />} />
      </Route>

      <Route
        path="courses/:id"
        element={
          <CourseDetails
            isAuthLoading={isAuthLoading}
            isAuth={isAuthenticated}
          />
        }
      />
      <Route
        path="/courses/:id/lecture/:lectureId"
        element={<OngoingLecture isAuth={isAuthenticated} />}
      >
        <Route index={true} element={<Lecture isAuth={isAuthenticated} />} />
      </Route>

      {/* questionnaire */}

      <Route
        path="questionnaire"
        element={
          <QuestionnaireRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            user={user}
          >
            <Questionnaire />
          </QuestionnaireRoute>
        }
      />

      {/* profile */}

      <Route
        path="profile"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/login"}
          >
            <Profile user={user} />
          </ProtectedRoute>
        }
      />

      {/* subscription */}

      <Route
        path="profile/plan"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/login"}
          >
            <Subscriptions/>
          </ProtectedRoute>
        }
      />

       {/* security */}

       <Route
        path="profile/security"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/login"}
          >
            <Security />
          </ProtectedRoute>
        }
      />

      {/* Rating */}

      <Route
        path="rating"
        element={
          <Rating isAuthLoading={isAuthLoading} isAuth={isAuthenticated} />
        }
      />
      <Route
        path="rating/:id"
        element={
          <StudentRating
            isAuthLoading={isAuthLoading}
            isAuth={isAuthenticated}
          />
        }
      />
      <Route
        path="rating/:userId/certificate/:certificateId"
        element={
          <CertificateDetails
            isAuthLoading={isAuthLoading}
            isAuth={isAuthenticated}
          />
        }
      />

      <Route
        path="dashboard"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/login"}
          >
            <UserRating />
          </ProtectedRoute>
        }
      />

      {/* Blog */}

      <Route path="/posts" element={<Posts />} />
      <Route path="/posts/:slug" element={<Post />} />

      {/* Events */}
      <Route path="/events" element={<Events />} />
      <Route path="/events/:slug" element={<Event />} />

      {/* mentorship */}

      <Route path="/mentorship" element={<Mentorship />} />
      <Route
        path="/mentorship/:id"
        element={<Mentor isAuth={isAuthenticated} />}
      />

      {/* instructor */}

      <Route
        path="/courses/:courseId/instructor/:instructorId"
        element={
          <Instructor isAuthLoading={isAuthLoading} isAuth={isAuthenticated} />
        }
      />

      {/* subscriptions */}

      <Route
        path="/subscriptions"
        element={<Plans isAuthLoading={isAuthLoading} />}
      />

      {/* 404 */}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
