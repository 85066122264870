import Hero from './sections/Hero/Hero';
import Meet from './sections/Meet/Meet';
import classes from './index.module.scss';
import Watch from './sections/Watch/Watch';
import { useParams } from 'react-router-dom';
import { getEventQueryBySlug } from './query';
import ForWho from './sections/ForWho/ForWho';
import { useQuery } from '@tanstack/react-query';
import { toCamelCase } from '../../../helpers/utils';
import WhySignUp from './sections/WhySignUp/WhySignUp';
import WhatsNext from './sections/WhatsNext/WhatsNext';
import { apiCms } from '../../../instances/axiosInstances';
import PostWebinar from './sections/PostWebinar/PostWebinar';
import Header from '../../../components/Layouts/components/Header';
import Footer from '../../../components/Layouts/components/Footer';
import StudentsFeedbacks from './sections/StudentsFeedbacks/StudentsFeedbacks';

const componentsMap = {
  hero: Hero,
  forWho: ForWho,
  meet: Meet,
  studentsFeedbacks: StudentsFeedbacks,
  whySignUp: WhySignUp,
  whatsNext: WhatsNext,
  postWebinar: PostWebinar,
  watch: Watch,
};

export default function Event() {
  const { slug } = useParams();

  const eventData = useQuery(['eventData', slug], () =>
    apiCms.get(`/events?${getEventQueryBySlug(slug)}`)
  );

  const pageData = eventData.data?.data?.data[0];
  const sections = pageData?.sections;

  if (!sections) return null;

  return (
    <>
      <Header variant='promo' />
      <main className={classes.root}>
        {sections.map((section, index) => {
          const componentName = toCamelCase(section.__component);
          const Component = componentsMap[componentName];

          if (!Component) {
            console.warn(`Component "${componentName}" not found!`);
            return null;
          }

          const additionalProps =
            componentName === 'hero' ? { title: pageData?.title } : {};

          return (
            <Component
              key={`${section.id}-${index}`}
              {...section}
              {...additionalProps}
            />
          );
        })}
      </main>
      <Footer variant='promo' />
    </>
  );
}
