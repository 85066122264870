import * as yup from "yup";
import { PASSWORD_REGEX } from '../../constants/regex';

export const updatePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Repeat password is required"),
});