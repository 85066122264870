import { Box, Typography } from '@mui/material';
import classes from './BulletList.module.scss';

export default function BulletList({ items, className }) {
  if (!items.length) return null;

  return items.map((item, index) => (
    <Box
      className={`${classes.listItem} ${className}`}
      key={index}
      component='ul'
    >
      <Box>
        <Box className={classes.listItemBullet}></Box>
      </Box>
      <Typography className={classes.itemText} component='li'>
        {item.text}
      </Typography>
    </Box>
  ));
}
