import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Container, Typography } from '@mui/material';
import classes from './TopStudents.module.scss';
import { api } from '../../../../instances/axiosInstances';
import { AccentButton } from '../../../../components/MUI/buttons/button';
import goldImg from '../../../../assets/images/home/top-1.png';
import silverImg from '../../../../assets/images/home/top-2.png';
import bronzeImg from '../../../../assets/images/home/top-3.png';
import goldStatusImg from '../../../../assets/images/home/status-1.png';
import silverStatusImg from '../../../../assets/images/home/status-2.png';
import bronzeStatusImg from '../../../../assets/images/home/status-3.png';
import studentPlaceholderImg from '../../../../assets/images/home/student-placeholder.jpg';

export default function TopStudents(props) {
  const itemsRes = useQuery(
    ['topStudents'],
    () => api.get(`/usersRating/unregistered`),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const items = itemsRes.isSuccess
    ? itemsRes.data?.data?.usersRating?.users
    : [];

  if (!items.length) return null;

  return (
    <Box className={classes.section} component='section'>
      <Container className={classes.container} maxWidth='lg'>
        <Typography component='h2' className={classes.title}>
          {props.title}
        </Typography>
        <Box className={classes.items}>
          {items.slice(0, 3).map((item, index) => {
            const first = index === 0;
            const second = index === 1;
            const third = index === 2;

            return (
              <Box className={classes.item} key={index}>
                <Box className={classes.imageBox}>
                  {first ? (
                    <img
                      className={classes.imageRating}
                      src={goldImg}
                      alt={''}
                    />
                  ) : null}
                  {second ? (
                    <img
                      className={classes.imageRating}
                      src={silverImg}
                      alt={''}
                    />
                  ) : null}
                  {third ? (
                    <img
                      className={classes.imageRating}
                      src={bronzeImg}
                      alt={''}
                    />
                  ) : null}
                  <img
                    className={classes.photo}
                    src={item.profilePhotoUrl || studentPlaceholderImg}
                    alt={''}
                  />
                </Box>
                <Typography
                  className={`
                    ${classes.placeText} 
                    ${first ? classes.first : ''}
                    ${second ? classes.second : ''} 
                    ${third ? classes.third : ''}
                  `}
                >
                  {first ? '1st' : null}
                  {second ? '2nd' : null}
                  {third ? '3rd' : null}
                </Typography>
                <Typography className={classes.itemTitle}>
                  {item?.fullName}
                </Typography>
                <Typography className={classes.itemScore}>
                  {first ? (
                    <img src={goldStatusImg} width={20} height={20} alt='' />
                  ) : null}
                  {second ? (
                    <img src={silverStatusImg} width={20} height={20} alt='' />
                  ) : null}
                  {third ? (
                    <img src={bronzeStatusImg} width={20} height={20} alt='' />
                  ) : null}
                  {item?.points?.score}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <AccentButton
          isBig={false}
          component={Link}
          to={props.link.link}
          className={classes.ctaButton}
        >
          {props.link.text}
        </AccentButton>
      </Container>
    </Box>
  );
}
