import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../../../instances/axiosInstances";
import { PlayButton } from "../../../../../components/MUI/buttons/button";

import classes from "./index.module.scss";
import { ProgressPopupContext } from "../../../../../context/ProgressPopupContext";

export default function Player({
  courseId,
  nextPage,
  videoLink,
  videoTitle,
  poster,
  lectureId,
  isPaid,
  isComplete,
  duration,
}) {
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const currentProgressRef = useRef(0);
  const queryClient = useQueryClient();
  const { handleOpen, handleClose } = useContext(ProgressPopupContext);

  const { mutate, isLoading } = useMutation(
    (data) => api.post(`/coursesLectures/${lectureId}/progress`, data),
    {
      onSuccess: async ({ data }) => {
        if (!isComplete && data?.coursesLectures?.isComplete) {
          await queryClient.invalidateQueries({
            queryKey: ["course", "content", "registered", courseId.toString()],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["course", "registered", courseId.toString()],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["landing", "registered"],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["paths", "registered"],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["user"],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["path", "registered"],
            refetchType: "all",
          });

          await queryClient.invalidateQueries({
            queryKey: ["all-courses", "registered"],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["my-courses", "registered"],
            refetchType: "all",
            exact: true,
          });

          await queryClient.invalidateQueries({
            queryKey: ["usersRating", "registered"],
            refetchType: "all",
          });
        }
      },
    }
  );

  const handleProgress = (progress) => {
    if (!isLoading && isPaid && !isComplete) {
      currentProgressRef.current = progress.playedSeconds.toFixed(3);
    }
  };

  const sendProgress = (playedSeconds) => {
    if (!isLoading && isPaid && !isComplete) {
      const body = JSON.stringify({
        progressTime: playedSeconds.toFixed(3) * 1000,
      });
      mutate(body);
    }
  };

  return (
    <Box className={classes.wrapper}>
      <ReactPlayer
        ref={playerRef}
        url={videoLink}
        width="100%"
        height="100%"
        controls={true}
        playsinline={true}
        progressInterval={1000}
        onEnded={() => {
          nextPage && navigate(`/courses/${courseId}/lecture/${nextPage.id}`);
          handleOpen();
        }}
        onPause={({ seconds }) => {
          sendProgress(seconds);
        }}
        onPlay={() => {
          sendProgress(currentProgressRef.current);
        }}
        onProgress={(data) => {
          handleProgress(data);
          data.playedSeconds * 1000 >= (90 / 100) * duration &&
            sendProgress(data.playedSeconds);
        }}
        onSeek={(data) => {
          handleClose();
          sendProgress(data);
        }}
        playIcon={
          <PlayButton
            variant="contained"
            sx={{
              width: "5.5rem",
              height: "5.5rem",
            }}
          >
            <PlayArrowIcon
              sx={{
                color: "var(--colors-a-100)",
                fontSize: "3rem",
              }}
            />
          </PlayButton>
        }
        config={{
          vimeo: {
            playerOptions: {
              autopause: true,
              controls: true,
              byline: false,
              portrait: false,
              loop: false,
              title: videoTitle,
            },
          },
        }}
        playing
        light={poster}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
}
