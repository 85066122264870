import { useEffect } from "react";
import ImageUpload from "./ImageUpload";
import QuestionnaireUnfilled from "./QuestionnaireUnfilled";
import QuestionnaireFilled from "./QuestionnaireFilled";
import { useQueryClient } from "@tanstack/react-query";
import SettingsLayout from '../../components/Layouts/SettingsLayout';
import SectionCard from '../../components/Common/SectionCard';

export default function Profile({ user }) {
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData(["user"]);
  const state = queryClient.getQueryState(["user"]);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <SettingsLayout title='Profile'>
      <SectionCard>
        {data && data.data.users.isQuestionnaireComplete ? (
          <QuestionnaireFilled
            user={data.data.users}
            isUserLoading={state.status === "loading"}
          />
        ) : (
          <QuestionnaireUnfilled email={user.email} />
        )}
      </SectionCard>

      <SectionCard title='Profile picture'>
        <ImageUpload
          profilePhotoUrl={data?.data.users.profilePhotoUrl}
          userId={data?.data.users.id}
        />
      </SectionCard>
    </SettingsLayout>
  );
}
