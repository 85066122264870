import classes from './Timer.module.scss';
import { useTimer } from '../../../../hooks/useTimer';
import { formatValue, setS } from '../../../../helpers/utils';

export default function Timer({ date }) {
  const { timeRemaining } = useTimer({ pstDateString: date });

  return (
    <div className={classes.timer}>
      <div className={classes.item}>
        <div className={classes.time}>{formatValue(timeRemaining?.days)}</div>
        <p className={classes.text}>{setS(timeRemaining?.days, 'DAY')}</p>
      </div>
      <div className={classes.divider}>:</div>
      <div className={classes.item}>
        <div className={classes.time}>{formatValue(timeRemaining?.hours)}</div>
        <p className={classes.text}>{setS(timeRemaining?.hours, 'HOUR')}</p>
      </div>
      <div className={classes.divider}>:</div>
      <div className={classes.item}>
        <div className={classes.time}>
          {formatValue(timeRemaining?.minutes)}
        </div>
        <p className={classes.text}>{setS(timeRemaining?.minutes, 'MINUTE')}</p>
      </div>
      <div className={classes.divider}>:</div>
      <div className={classes.item}>
        <div className={classes.time}>
          {formatValue(timeRemaining?.seconds)}
        </div>
        <p className={classes.text}>{setS(timeRemaining?.seconds, 'SECOND')}</p>
      </div>
    </div>
  );
}
