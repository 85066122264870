import classes from './index.module.scss';
import { Box, Typography } from '@mui/material';
import ModalHeader from '../../security/DeleteProfileModal/ModalHeader';
import { RedButton, WhiteButton } from '../../../components/MUI/buttons/button';
import { useCancelSubscriptionApi } from '../../../apis/payments';
import { useQueryClient } from '@tanstack/react-query';
import errorToast from '../../../components/toasts/errorToast';
import successToast from '../../../components/toasts/successToast';
import { Link } from 'react-router-dom';

export default function CancelSubscirptionModal({ handleClose }) {
  const queryClient = useQueryClient();

  const cancelSubscriptionApi = useCancelSubscriptionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plans'],
        refetchType: 'all',
      });
      await queryClient.invalidateQueries({
        queryKey: ['currentSubscription'],
        refetchType: 'all',
      });
      handleClose();
      successToast(
        'Subscription canceled. Plan active until next billing period'
      );
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader title="Cancel subscription?" handleClose={handleClose} />
      <Typography className={classes.text}>
        Downgrade to Starter (Free) plan changes will be applied starting next
        billing month. Until then, you can continue using current plan features. 
        You are eligible for a refund during 14-day period from the first
        transaction. Please check{" "}
        <Link to={"/terms-and-conditions"}>Terms&Conditions</Link>.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleClose}
          isBig={false}
          variant="contained"
          disabled={cancelSubscriptionApi.isLoading}
        >
          Close
        </WhiteButton>
        <RedButton
          isBig={false}
          variant="contained"
          onClick={cancelSubscriptionApi.mutate}
          disabled={cancelSubscriptionApi.isLoading}
        >
          Yes, cancel
        </RedButton>
      </Box>
    </Box>
  );
}
