import Carousel from './Carousel';
import { useContext } from 'react';
import { Box } from '@mui/material';
import classes from './index.module.scss';
import VideoModal from '../../EmbedVideos/VideoWrapper';
import { ModalContext } from '../../../../context/ModalContext';

export default function Reviews({ data, sliderRef }) {
  const { handleModalOpen } = useContext(ModalContext);

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.carousel}>
        <Carousel sliderRef={sliderRef} showModal={showModal} slides={data} />
      </Box>
    </Box>
  );
}
