import Heading from '../../Heading/Heading';
import classes from './PostWebinar.module.scss';

export default function PostWebinar(props) {
  if (!props || !props.id) return null;

  const { title, items, image } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <Heading className={classes.heading}>{title}</Heading>
          {items.map((item, index) => (
            <div key={index} className={classes.item}>
              <h3 className={classes.title}>{item.title}</h3>
              <p className={classes.text}>{item.text}</p>
            </div>
          ))}
        </div>
        <div className={classes.imageBox}>
          <img src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`} alt='' />
        </div>
      </div>
    </section>
  );
}
