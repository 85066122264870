import { api } from "../../../../../instances/axiosInstances";
import { setCoursePath, setLecturesPath } from "../paths";

export const fetchCourse = async ({ isAuth, id }) => {
  const data = await api.get(setCoursePath({ isAuth, id }));
  return data;
};

export const fetchNextCourse = async ({ pathId, courseId }) => {
  const data = await api.get(
    `/courses/path/${pathId}/next-course?courseId=${courseId}`
  );
  return data;
};

export const fetchLecture = async ({ isAuth, lectureId }) => {
  const data = await api.get(setLecturesPath({ isAuth, lectureId }));
  return data;
};


