import s from './CtaButton.module.scss';

export default function CtaButton({ children, withGlow = true, href }) {
  const classes = `${s.base} ${withGlow ? s.glow : ''}`;

  if (href) {
    return (
      <a
        className={classes}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span className={s.inner}>{children}</span>
      </a>
    );
  }

  return (
    <button className={classes}>
      <span className={s.inner}>{children}</span>
    </button>
  );
}
