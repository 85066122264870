import { useQuery } from '@tanstack/react-query';
import { Box, Container, Typography } from '@mui/material';
import classes from './FeaturedCourses.module.scss';
import { api } from '../../../../instances/axiosInstances';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import Carousel from './Carousel';

export default function FeaturedCourses({ title }) {
  const { auth } =
    useContext(AuthContext);
  const coursesApiResponse = useQuery(
    [`${auth ? "courses" : "courses unregistered"}`],
    () => api.get(`${auth ? "/courses" : "/courses/unregistered"}`),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const items = coursesApiResponse.isSuccess
    ? coursesApiResponse.data?.data?.courses
    : [];

  if (!items || !items.length) return null;

  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2">
          {title}
        </Typography>
        <Carousel data={items} />
      </Container>
    </Box>
  );
}
