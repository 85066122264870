import CtaButton from '../../CtaButton/CtaButton';
import SuccessStories from '../../../../../components/Common/SuccessStories/SuccessStories';

export default function StudentsFeedbacks(props) {
  if (!props || !props.id) return null;

  const { title, links } = props || {};

  return (
    <SuccessStories
      title={title}
      variant='promo'
      button={<CtaButton href={links.link}>{links.text}</CtaButton>}
    />
  );
}
