import { useDeferredValue } from 'react';
import { Box } from '@mui/material';
import classes from './index.module.scss';
import PostCard from '../../../../components/MUI/cards/PostCard';

export default function List({ events }) {
  const deferredEvents = useDeferredValue(events || []);

  if (!deferredEvents || !deferredEvents.length) return null;

  return (
    <Box component={'ul'} className={classes.list}>
      {deferredEvents.map((item) => (
        <Box key={item.id} component={'li'} className={classes.list__item}>
          <PostCard data={item} variant='event' link={`/events/${item.slug}`} />
        </Box>
      ))}
    </Box>
  );
}
