import { Box, Typography } from "@mui/material";
import { AccentButton } from "../../../../../components/MUI/buttons/button";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext } from "react";
import useMixpanel from "../../../../../hooks/useMixpanel";
import { mixpanelLecture } from "../../../../../constants/mixpanel";
import classes from "./index.module.scss";
import { SubscriptionsContext } from "../../../../../context/SubscriptionsContext";

export default function GetFullAccess({
  isFree,
}) {
  const { pathname } = useLocation();
  const {id} = useParams();
  const { setCourseRedirectPath, setSelectedCourseId } =
    useContext(SubscriptionsContext);
  const { handleEventTrack } = useMixpanel();
  const { signUpLink, buyForLink } = mixpanelLecture;
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Typography component={"h3"} className={classes.title}>
          {isFree ? "🔒 Authorization needed" : "🔒 Get full access"}
        </Typography>
        <Typography className={classes.text}>
          {isFree
            ? "Please authorize on the platform to proceed"
            : "You’re trying to open a lecture that is available only with paid subscription. "}
        </Typography>
      </Box>
      <Box className={classes.actions}>
        {isFree ? (
          <AccentButton
            isBig={false}
            variant={"contained"}
            component={Link}
            to={"/registration"}
            onClick={(e) => {
              handleEventTrack(e, {
                ...signUpLink,
                options: {
                  ...signUpLink.options,
                },
              });
              setCourseRedirectPath(pathname);
              setSelectedCourseId(id);
            }}
          >
            Sign up / Login
          </AccentButton>
        ) : (
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to={"/subscriptions"}
            onClick={(e) => {
              handleEventTrack(e, {
                ...buyForLink,
                options: {
                  ...buyForLink.options,
                },
              });
              setCourseRedirectPath(pathname);
              setSelectedCourseId(id)
            }}
          >
            Change Plan
          </AccentButton>
        )}
      </Box>
    </Box>
  );
}
