import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import classes from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import CustomNavigation from "./CustomNavigation";

SwiperCore.use([Autoplay]);

export default function Carousel({ data, showModal }) {
  const sliderRef = useRef();
  const wrapperRef = useRef();
  const [isSliderDisabled, setIsSliderDisabled] = useState(true);
  const [slides, setSlides] = useState([]);
  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    const checkSliderCondition = () => {
      if (!wrapperRef.current || !sliderRef.current) return;
      const swiperContainerWidth = wrapperRef.current.offsetWidth;
      const slidesArray = Array.from(
        sliderRef.current.querySelectorAll(".swiper-slide__youtube")
      ).slice(0, 3);
      const gap = window.innerWidth >= 768 ? 24 : 12;
      const totalSlidesWidth =
        slidesArray.reduce((acc, slide) => acc + slide.offsetWidth, 0) +
        (slidesArray.length - 1) * gap;

      setIsSliderDisabled(totalSlidesWidth <= swiperContainerWidth);
    };

    checkSliderCondition();

    window.addEventListener("resize", checkSliderCondition);
    return () => window.removeEventListener("resize", checkSliderCondition);
  }, [slides]);

  useEffect(() => {
    isSliderDisabled
      ? setSlides([...data].slice(0, 3))
      : setSlides(Array(2).fill(slides).flat());
    setKey(Date.now());
  }, [isSliderDisabled]);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Swiper
        key={key}
        slidesPerView={"auto"}
        spaceBetween={12}
        ref={sliderRef}
        className={classes.swiper}
        loop={true}
        speed={2000}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          768: {
            spaceBetween: 24,
          },
        }}
      >
        {slides.map((video, index) => {
          return (
            <SwiperSlide
              onClick={() => {
                showModal({
                  title: video.title,
                  url: `https://youtu.be/${video.videoId}`,
                });
              }}
              className={`${classes.slide} swiper-slide__youtube`}
              key={`${video.id}-${index}`}
            >
              <img
                // className={classes.image}
                src={video.thumbnail.url}
                width={video.thumbnail.width}
                height={video.thumbnail.height}
                alt={video.title}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {isSliderDisabled ? null : <CustomNavigation sliderRef={sliderRef} />}
    </div>
  );
}
