import { Box, LinearProgress, Typography } from "@mui/material";
import useProgress from "../../../pages/courses/OngoingLecture/Modules/hooks/useProgress";
import useFinishedCourseLecturesCount from "../../../pages/courses/OngoingLecture/Modules/hooks/useFinishedCourseLecturesCount";
import useCourseLecturesCount from "../../../pages/courses/OngoingLecture/Modules/hooks/useCourseLecturesCount";
import classes from "./index.module.scss";

export default function Progress({ course }) {
  const { courseLecturesCount } = useCourseLecturesCount({ course });
  const { finishedCourseLecturesCount } = useFinishedCourseLecturesCount({
    course,
  });
  const { progress } = useProgress({
    course,
    courseLecturesCount,
    finishedCourseLecturesCount,
  });
  return (
    <Box className={classes.progress}>
      <Typography className={classes.progress__title} component="h2">
        {`Finished ${finishedCourseLecturesCount} / ${courseLecturesCount} lectures`}
      </Typography>

      <LinearProgress
        className={`${classes.progress__bar} ${
          course.isComplete ? classes.progress__bar_green : ""
        }`}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
