export const toCamelCase = (str) =>
  str
    .split('.')
    .pop()
    .replace(/-([a-z])/g, (_, char) => char.toUpperCase());

export function formatValue(value) {
  return value < 10 ? '0' + value.toString() : value.toString();
}

export function setS(value, string) {
  return Number(value) === 1 ? string : string + 'S';
}

    