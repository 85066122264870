import { Box, Container, Typography } from '@mui/material';
import classes from './TopAdvantages.module.scss';
import { AccentButton } from '../../../../components/MUI/buttons/button';
import { Link } from 'react-router-dom';
import { ReactComponent as DiscordIcon } from '../../../../assets/icons/Discord.svg';
import BulletList from '../../../../components/Common/BulletList/BulletList';

export default function TopAdvantages(props) {
  return (
    <Box className={classes.section} component='section'>
      <Container className={classes.container} maxWidth='lg'>
        <Typography className={classes.title} component='h2'>
          {props.title}
        </Typography>
        <Box className={classes.box}>
          {props.blocks.map((item, index) => (
            <Box className={classes.item} key={index}>
              <Box className={classes.imageBox}>
                <img
                  src={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.url}`}
                  alt={item.title || ''}
                />
              </Box>
              <Box className={classes.info}>
                <Typography className={classes.itemTitle} component='h3'>
                  {item.title}
                </Typography>
                <Typography className={classes.itemDescription} component='p'>
                  {item.description}
                </Typography>
                <Box className={classes.advantageList}>
                  <BulletList items={item.list} />
                </Box>
                <AccentButton
                  isBig={false}
                  component={Link}
                  to={item.link.link}
                  target={'_blank'}
                  className={classes.ctaButton}
                >
                  {item.link.icon === 'discord' ? <DiscordIcon /> : null}
                  {item.link.text}
                </AccentButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
