import qs from 'qs';

export const homeQuery = qs.stringify({
  populate: {
    sections: {
      on: {
        'sections.featured-courses': {
          populate: '*',
        },
        'sections.webinar-banner': {
          populate: '*',
        },
        'sections.hero': {
          populate: '*',
        },
        'sections.text-marquee': {
          populate: '*',
        },
        'sections.number-one': {
          populate: {
            image: true,
            link: true,
            items: {
              populate: '*',
            },
          },
        },
        'sections.top-students': {
          populate: '*',
        },
        'sections.student-journey': {
          populate: {
            link: true,
            items: {
              populate: {
                list: {
                  populate: '*',
                },
              },
            },
          },
        },
        'sections.companies': {
          populate: '*',
        },
        'sections.success-stories': {
          populate: '*',
        },
        'sections.top-advantages': {
          populate: {
            blocks: {
              populate: '*',
            },
          },
        },
        'sections.weekly-you-tube': {
          populate: '*',
        },
        'sections.start-learning': {
          populate: '*',
        },
      },
    },
  },
});
