import classes from './index.module.scss';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { api } from '../../../instances/axiosInstances';
import { useQuery } from '@tanstack/react-query';
import ModalHeader from '../../security/DeleteProfileModal/ModalHeader';
import { ReactComponent as LinkIcon } from '../../../assets/icons/invoice-link.svg';
import { formatDate } from '../../../helpers/formatDateTime';
import Loader from '../../../components/Common/Loader';

const columns = [
  'Product',
  'Date',
  'Price',
  'Status',
  'Transaction ID',
  'Details',
];

export default function InvoicesModal({ handleClose }) {
  const { data, isLoading } = useQuery(['transactions'], () =>
    api.get('/transactions/user')
  );

  if (isLoading) return <Loader />;

  const transactions = data?.data?.transactions;

  return (
    <Box className={classes.root}>
      <ModalHeader title='Invoices' handleClose={handleClose} />
      <Box className={classes.tableContainer}>
        {transactions?.length > 0 ? (
          <table>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transactions.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row.subscriptionPlan?.name || 'Free'}</td>
                  <td>{formatDate(new Date(row.createdAt))}</td>
                  <td>{row.price}</td>
                  <td
                    className={`${classes.status} ${
                      row.status.includes('fail') ? classes.fail : ''
                    }`}
                  >
                    {row.status}
                  </td>
                  <td>#{row.stripeInvoiceId}</td>
                  <td>
                    <Link to={row.stripeInvoicePdf || '#'}>
                      <LinkIcon />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </Box>
    </Box>
  );
}
