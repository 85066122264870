import Timer from '../../Timer/Timer';
import classes from './Hero.module.scss';
import CtaButton from '../../CtaButton/CtaButton';

export default function Hero(props) {
  if (!props || !props.id) return null;

  const { badge, title, subTitle, date, link, bottomText, image } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <div className={classes.badge}>{badge}</div>
          <div>
            <h1 className={classes.heading}>{title}</h1>
            <p className={classes.subHeading}>{subTitle}</p>
          </div>
          <p className={classes.dateText}>🗓️ {date}</p>
          <Timer date={date} />
          <CtaButton withGlow={false} href={link.link}>
            {link.text}
          </CtaButton>
          <p className={classes.smallText}>{bottomText}</p>
        </div>
        <div className={classes.imageBox}>
          <img
            src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
            alt={title}
          />
        </div>
      </div>
    </section>
  );
}
