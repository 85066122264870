import bgImg from './bg.png';
import classes from './ImageClip.module.scss';

export default function ImageClip({ imgUrl }) {
  return (
    <div className={classes.root}>
      <img
        className={`${classes.bgImg} ${classes.sectionImg}`}
        src={bgImg}
        alt=''
      />
      <div className={`${classes.imgContainer} ${classes.sectionVar}`}>
        <div
          className={classes.maskedImage}
          style={{ backgroundImage: `url(${imgUrl})` }}
        >
          <svg width='0' height='0'>
            <defs>
              <clipPath id='svgPathSection' clipPathUnits='objectBoundingBox'>
                <path d='M0.003,0.19 C0.003,0.16 0.049,0.14 0.071,0.14 H0.568 C0.595,0.14 0.615,0.114 0.615,0.084 V0.079 C0.615,0.028 0.634,0 0.656,0 H0.802 C0.828,0 0.842,0.051 0.842,0.079 V0.084 C0.842,0.114 0.862,0.14 0.889,0.14 H0.925 C0.967,0.14 0.968,0.162 0.968,0.19 V0.818 C0.968,0.846 0.948,0.869 0.926,0.869 H0.613 C0.587,0.869 0.567,0.892 0.567,0.922 V0.929 C0.567,0.957 0.548,0.979 0.526,0.979 H0.071 C0.048,0.979 0.003,0.957 0.003,0.929 V0.19 Z' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
