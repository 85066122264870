import Heading from '../../Heading/Heading';
import classes from './ForWho.module.scss';

export default function ForWho(props) {
  if (!props || !props.id) return null;

  const { title, subTitle, list, image } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <div className={classes.info}>
          <Heading>{title}</Heading>
          <p className={classes.subHeading}>{subTitle}</p>
          <ul className={classes.list}>
            {list && list.length > 0
              ? list.map((item, index) => (
                  <li key={index} className={classes.item}>
                    {item.text}
                  </li>
                ))
              : null}
          </ul>
        </div>
        <div className={classes.imageBox}>
          <img
            src={`${process.env.REACT_APP_CMS_DOMAIN}${image.url}`}
            alt={title}
          />
        </div>
      </div>
    </section>
  );
}
