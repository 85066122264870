import { api } from '../instances/axiosInstances';
import { useMutation } from '@tanstack/react-query';

export const useStripeSetupIntentApi = (options = {}) => {
  const { onSuccess = () => {}, onError = () => {} } = options;

  return useMutation(() => api.post('/subscriptions/stripe/setup-intent'), {
    onSuccess: async (...args) => {
      onSuccess(...args);
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useCancelDowngradeApi = (options = {}) => {
  const { onSuccess = () => {}, onError = () => {} } = options;

  return useMutation(() => api.post('/subscriptions/stripe/downgrade/cancel'), {
    onSuccess: async (...args) => {
      onSuccess(...args);
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useKeepSubscriptionApi = (options = {}) => {
  const { onSuccess = () => {}, onError = () => {} } = options;

  return useMutation(() => api.post('/subscriptions/stripe/keep'), {
    onSuccess: async (...args) => {
      onSuccess(...args);
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useCancelSubscriptionApi = (options = {}) => {
  const { onSuccess = () => {}, onError = () => {} } = options;

  return useMutation(() => api.post("/subscriptions/stripe/downgrade/free"), {
    // return useMutation(() => api.delete('/subscriptions/stripe/cancel'), {
    onSuccess: async (...args) => {
      onSuccess(...args);
    },
    onError: (error) => {
      onError(error);
    },
  });
};
