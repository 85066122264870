import React from "react";
import classes from "./index.module.scss";
import PlanCard from "../PlanCard";

export default function PlansList({ list }) {
  return (
    <ul className={classes.list}>
      {list.map((card) => (
        <li key={card.id} className={classes.list__item}>
          <PlanCard card={card} />
        </li>
      ))}
    </ul>
  );
}
