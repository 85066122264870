import { Link, useLocation } from 'react-router-dom';
import classes from './index.module.scss';

export default function SettingsNavigation() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className={classes.root}>
      <Link 
        to="/profile" 
        className={`${classes.link} ${isActive('/profile') ? classes.active : ''}`}
      >
        General
      </Link>
      <Link 
        to="/profile/plan" 
        className={`${classes.link} ${isActive('/profile/plan') ? classes.active : ''}`}
      >
        Plan
      </Link>
      <Link 
        to="/profile/security" 
        className={`${classes.link} ${isActive('/profile/security') ? classes.active : ''}`}
      >
        Security
      </Link>
    </nav>
  );
}
