import {
  AccentButton,
  WhiteButton,
} from '../../../components/MUI/buttons/button';
import { Box, Typography } from '@mui/material';
import classes from './index.module.scss';
import ModalHeader from '../../security/DeleteProfileModal/ModalHeader';
import { useKeepSubscriptionApi } from '../../../apis/payments';
import successToast from '../../../components/toasts/successToast';
import { useQueryClient } from '@tanstack/react-query';
import errorToast from '../../../components/toasts/errorToast';

export default function KeepSubscriptionModal({ handleClose }) {
  const queryClient = useQueryClient();

  const keepSubscriptionApi = useKeepSubscriptionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['currentSubscription'],
        refetchType: 'all',
      });
      handleClose();
      successToast('Subscription kept. No charges applied, plan unchanged');
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader title='Keep subscription?' handleClose={handleClose} />
      <Typography className={classes.text}>
        No additional charges will be made. You will remain on your current
        subscription plan.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleClose}
          isBig={false}
          variant='contained'
          disabled={keepSubscriptionApi.isLoading}
        >
          Close
        </WhiteButton>
        <AccentButton
          isBig={false}
          variant='contained'
          onClick={keepSubscriptionApi.mutate}
          disabled={keepSubscriptionApi.isLoading}
        >
          Keep subscription
        </AccentButton>
      </Box>
    </Box>
  );
}
