import React from "react";
import Marquee from "react-fast-marquee";
import classes from "./index.module.scss";

export default function ImagesMarquee({props}) {
  if (!props) return null;
  return (
    <section className={classes.wrapper}>
      <Marquee autoFill className={classes.marquee}>
        {props.map((item, index) => (
          <img
          key={`${item.id}-${index}`}
            className={classes.image}
            src={`${process.env.REACT_APP_CMS_DOMAIN}${item.url}`}
            alt={item.name}
            width={item.width}
          />
        ))}
      </Marquee>
    </section>
  );
}
