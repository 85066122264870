import React from "react";
import useShowPassword from "../../../hooks/useShowPassword";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { Input } from "../../../components/MUI/inputs";
import ShowPasswordIcon from "../../../components/Common/ShowPasswordIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BackLink from "../../../components/Common/BackLink";
import classes from "./index.module.scss";

export default function Step1({
  register,
  errors,
  trigger,
  setValue,
  getValues,
}) {

  const {
    handleClickShowPassword: handleShowNewPassword,
    showPassword: showNewPassword,
  } = useShowPassword();
  const {
    handleClickShowPassword: handleShowConfirmedPassword,
    showPassword: showConfirmedPassword,
  } = useShowPassword();
  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    trigger(["phoneNumber"]);
    setValue("phoneNumber", formattedValue);
  };

  return (
    <>
      <BackLink path={"/registration/resend-password"} />
      <Typography component={"h2"} variant={"h2"} className={classes.title}>
        Onboarding info
      </Typography>
      <Typography className={classes.subtitle}>
        Please complete onboarding to proceed to the platform
      </Typography>
      <Input
        value={
          getValues("firstName") ||
          JSON.parse(localStorage.getItem("user"))?.firstName ||
          ""
        }
        label="First name*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="First name"
        {...register("firstName", {
          onChange: () => trigger(["firstName"]),
        })}
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName.message}
      />
      <Input
        value={
          getValues("lastName") ||
          JSON.parse(localStorage.getItem("user"))?.lastName ||
          ""
        }
        label="Last name*"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Last name"
        {...register("lastName", {
          onChange: () => trigger(["lastName"]),
        })}
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName.message}
      />
      <Box>
        <PhoneInput
          country={"us"}
          countryCodeEditable={false}
          copyNumbersOnly={false}
          value={getValues("phoneNumber") ? getValues("phoneNumber") : ""}
          containerClass={`${classes.phone} ${
            errors.phoneNumber ? classes.phone__error : ""
          }`}
          onChange={handlePhoneInputChange}
          inputProps={{
            autoComplete: "new-password",
          }}
          specialLabel="Phone Number*"
        />
        {errors.phoneNumber && (
          <Typography
            sx={{
              color: "var(--colors-b-100)",
              fontSize: "0.75rem",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "120%",
              marginLeft: "0.5rem",
              marginTop: "3px",
            }}
          >
            {errors.phoneNumber.message}
          </Typography>
        )}
      </Box>
      {!(
        JSON.parse(localStorage.getItem("user"))?.providerType ||
        JSON.parse(localStorage.getItem("sub"))
      ) ? (
        <>
          <Input
            label="Password*"
            type={showNewPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowNewPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showNewPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Create password"
            {...register("password", {
              onChange: () => trigger(["password"]),
            })}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
          <Input
            label="Confirm password*"
            type={showConfirmedPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmedPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showConfirmedPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Confirm password"
            {...register("confirmPassword", {
              onChange: () => trigger(["confirmPassword"]),
            })}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
          />
        </>
      ) : null}
    </>
  );
}
