import Heading from '../../Heading/Heading';
import classes from './WhySignUp.module.scss';
import CtaButton from '../../CtaButton/CtaButton';

export default function WhySignUp(props) {
  if (!props || !props.id) return null;

  const { title, items, link } = props || {};

  return (
    <section className={classes.section}>
      <div className={classes.container}>
        <Heading className={classes.heading}>{title}</Heading>
        <div className={classes.list}>
          {items.map((item, index) => (
            <article className={classes.card} key={index}>
              <img
                alt={item.title}
                className={classes.icon}
                src={`${process.env.REACT_APP_CMS_DOMAIN}${item.image.url}`}
              />
              <h3 className={classes.text}>{item.title}</h3>
            </article>
          ))}
        </div>
        <CtaButton href={link.link}>{link.text}</CtaButton>
      </div>
    </section>
  );
}
