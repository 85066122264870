import { Box } from "@mui/system";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { ReactComponent as Facebook } from "../../../assets/images/facebook-auth.svg";
import { ReactComponent as Google } from "../../../assets/images/google-auth.svg";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import classes from "./index.module.scss";
// import DiscordLogin from "./DiscordLogin";
import { GreyButton } from "../../MUI/buttons/button";
import { useNavigate } from "react-router-dom";
import useMixpanel from "../../../hooks/useMixpanel";
import { mixpanelSignIn } from "../../../constants/mixpanel";
import errorToast from "../../toasts/errorToast";
import { parseJwt } from "../../../helpers/parseJwt";

export default function SocialAuth() {
  const [providerType, setProviderType] = useState("");
  const { setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { handleEventTrack } = useMixpanel();
  const { signInBySocialMedia } = mixpanelSignIn;

  const { mutate } = useMutation(
    async ({ data, provider }) => {
      try {
        setProviderType(provider);
        const res = await api.post(
          `/auth/${provider}`,
          data
        );
        window.dataLayer.push({
          event: "sign_in",
          type: provider,
        });
        localStorage.setItem("accessToken", data.accessToken);
        return res;
      } catch (error) {
        errorToast(error.response.data.message);
      }
    },
    {
      onSuccess: ({ data }) => {
        if (data.isExist === false) {
          localStorage.removeItem("createProfileToken");
          localStorage.removeItem("sub");

          localStorage.setItem("user", JSON.stringify(data));
          data.multipleUsageToken &&
            localStorage.setItem("multipleUsageToken", data.multipleUsageToken);
          navigate("/registration/create-profile");
        }

        if (data.accessToken && data.refreshToken) {
          const user = parseJwt(data.accessToken);
          if (user.isQuestionnaireComplete) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("createProfileToken");
            localStorage.removeItem("sub");
            localStorage.removeItem("user");
            localStorage.removeItem("multipleUsageToken");
            setAuthUser(data);
            handleEventTrack(null, {
              ...signInBySocialMedia,
              options: {
                ...signInBySocialMedia.options,
                name: `User by ${providerType}`,
              },
            });
          } else {
            localStorage.removeItem("user");
            localStorage.removeItem("multipleUsageToken");
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("createProfileToken", data.refreshToken);
            localStorage.setItem("sub", user.sub);
            navigate("/registration/create-profile");
          }
        }
      },

      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );
  return (
    <Box className={classes.wrapper}>
      {/* <DiscordLogin mutation={mutate} /> */}
      <LoginSocialGoogle
        className={classes.google}
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        isOnlyGetToken={true}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={({ provider, data }) => {
          mutate({ data: { accessToken: data.access_token }, provider });
        }}
        onReject={(err) => {
          errorToast(err);
        }}
      >
        <GreyButton isBig={false} type="button" fullWidth variant="contained">
          <Google />
        </GreyButton>
      </LoginSocialGoogle>
      <LoginSocialFacebook
        className={classes.facebook}
        appId={process.env.REACT_APP_FB_APP_ID || ""}
        fieldsProfile={"id"}
        isOnlyGetToken={true}
        onResolve={({ provider, data }) => {
          mutate({ data: { accessToken: data.accessToken }, provider });
        }}
        onReject={(err) => {
          errorToast(err);
        }}
      >
        <GreyButton isBig={false} type="button" fullWidth variant="contained">
          <Facebook />
        </GreyButton>
      </LoginSocialFacebook>
    </Box>
  );
}
