import qs from 'qs';

export const getEventsQuery = ({
  categoryId,
  tagIds,
  searchText,
  page = 1,
  pageSize = 25,
}) => {
  const filters = {};

  if (categoryId) {
    filters.categories = {
      id: {
        $eq: categoryId,
      },
    };
  }

  if (tagIds?.length) {
    filters.tags = {
      id: {
        $in: tagIds,
      },
    };
  }

  if (searchText) {
    filters.slug = {
      $containsi: searchText,
    };
  }

  return qs.stringify({
    filters,
    populate: {
      image: true,
      tags: {
        populate: '*',
      },
      categories: {
        populate: '*',
      },
    },
    pagination: {
      page,
      pageSize,
    },
    sort: ['createdAt:desc'],
  });
};
