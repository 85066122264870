import { Box, Typography } from '@mui/material';
import classes from './index.module.scss';

export default function SectionCard ({ title, children }) {
  return (
    <Box className={classes.body}>
      <Box className={classes.content}>
        {title ? <Typography
          component={"h2"}
          variant={"h2"}
          className={classes.content__title}
        >
          {title}
        </Typography> : null}
        {children}
      </Box>
    </Box>
  )
}