import React from 'react';
import {
  AccentButton,
  WhiteButton,
} from '../../../../components/MUI/buttons/button';
import classes from './StartLearning.module.scss';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function StartLearning(props) {
  return (
    <Box className={classes.section} component='section'>
      <Container className={classes.container} maxWidth='lg'>
        <Typography className={classes.title} component='h2'>
          {props.title}
        </Typography>
        <Typography className={classes.description} component='p'>
          {props.description}
        </Typography>

        <Box className={classes.actions}>
          {props.links.map((item, index) => (
            <React.Fragment key={index}>
              {index === 0 ? (
                <AccentButton
                  isBig={false}
                  component={Link}
                  to={item.link}
                  className={classes.ctaButton}
                >
                  {item.text}
                </AccentButton>
              ) : (
                <WhiteButton
                  isBig={false}
                  component={Link}
                  variant='outlined'
                  target={'_blank'}
                  className={classes.button}
                  to={item.link}
                >
                  {item.text}
                </WhiteButton>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
