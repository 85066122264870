import Rating from "../../Rating"
import { Link } from 'react-router-dom';
import classes from './index.module.scss';

export default function CourseCard({ course }) {
  const options = {
    rating: Number(course.rating),
    votes: course.reviewsCount || 0,
    stars: Number(course.rating),
    readOnly: true,
  };

  const renderLevel = (level) => {
    switch (level) {
      case 0:
        return "Basic";
      case 1:
        return "Intermediate";
      case 2:
        return "Advanced";
      default:
        return null;
    }
  };

  return (
    <Link to={`/courses/${course.id}`} className={classes.card}>
      <div className={classes.image}>
        <img src={course.promoImageUrl} alt={course.title} />
      </div>
      <div className={classes.info}>
        <div className={classes.header}>
          <div className={classes.tags}>
            {course.type === "recorded" ? (
              <div className={`${classes.tag} ${classes.recorded}`}>
                Self-paced
              </div>
            ) : null}
            {course.type === "live" ? (
              <div className={`${classes.tag} ${classes.live}`}>Live</div>
            ) : null}
            <div className={classes.tag}>
              {renderLevel(course.difficultyLevel)}
            </div>
          </div>
          <h3 className={classes.title}>{course.title}</h3>
        </div>
        <Rating options={options} aria-label="course rating" />
      </div>
    </Link>
  );
}
