const getMonthName = (month) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month];
};

const getWeekdayName = (weekday) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return weekdays[weekday];
};

const formatTime = (date) => {
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const getFormattedDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  }).format(date);
};

export const formatDateTime = (dateTimeStr) => {
  const dateObj = new Date(dateTimeStr);

  const monthName = getMonthName(dateObj.getUTCMonth());
  const day = dateObj.getUTCDate();
  const weekDay = getWeekdayName(dateObj.getUTCDay());
  const formattedTime = formatTime(dateObj);
  const formattedDate = getFormattedDate(dateObj);

  return {
    date: `${monthName} ${day}`,
    time: formattedTime,
    weekDay,
    formattedDate
  };
};

export function formatDate(date) {
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}