import { Box, Typography } from '@mui/material';
import PlatformLayout from '../PlatformLayout';
import classes from './index.module.scss'
import SettingsNavigation from './SettingsNavigation';

export default function SettingsLayout({ title, children }) {
  return (
    <PlatformLayout>
      <Box className={classes.body}>
        <Typography component={"h1"} variant={"h1"} className={classes.title}>
          {title}
        </Typography>
        <SettingsNavigation/>
        <Box className={classes.content}>
          {children}
        </Box>
      </Box>
    </PlatformLayout>
  )
}