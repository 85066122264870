import { createContext, useCallback, useMemo, useState } from "react";
export const ProgressPopupContext = createContext();

export default function ProgressPopupContextProvider({ children }) {
  const [open, setOpen] = useState(false);

  const body = document.body;

  const handleOpen = useCallback(() => {
    setOpen(true);
    body.style.overflow = "hidden";
  }, [body.style]);

  const handleClose = useCallback(() => {
    setOpen(false);
    body.style.overflow = "auto";
  }, [body.style]);

  const getCurrentPopupName = (progress) => {
    switch (true) {
      case progress >= 30 && progress < 50:
        return "popup1";
      case progress >= 50 && progress < 100:
        return "popup2";
      case progress === 100:
        return "popup3";

      default:
        return null;
    }
  };

  const getCurrentPopupContent = ({ popupsContent, popupName }) =>
    popupsContent[popupName];

  const handleHideAllPopups = () => {
    localStorage.getItem("coursePopupPreference")
      ? localStorage.removeItem("coursePopupPreference")
      : localStorage.setItem("coursePopupPreference", "true");
  };

  const handleHideCurrentPopup = ({ popupName }) => {
    const storedData = sessionStorage.getItem("coursePopupPreference");
    const popupsArray = storedData ? JSON.parse(storedData) : [];
    const newPopupsArray = [...new Set([...popupsArray, popupName])];
    sessionStorage.setItem(
      "coursePopupPreference",
      JSON.stringify(newPopupsArray)
    );
  };

  const isCurrentPopupHidden = ({ popupName }) => {
    const storedData = sessionStorage.getItem("coursePopupPreference");
    const popupsArray = storedData ? JSON.parse(storedData) : [];
    return popupsArray ? popupsArray.includes(popupName) : false;
  };

  const value = useMemo(
    () => ({
      open,
      handleOpen,
      handleClose,
      getCurrentPopupName,
      getCurrentPopupContent,
      handleHideAllPopups,
      handleHideCurrentPopup,
      isCurrentPopupHidden,
    }),
    [handleClose, handleOpen, open]
  );

  return (
    <ProgressPopupContext.Provider value={value}>
      {children}
    </ProgressPopupContext.Provider>
  );
}
