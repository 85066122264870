import React from 'react';
import { Link } from 'react-router-dom';
import classes from './StudentJourney.module.scss';
import { Box, Container, Typography } from '@mui/material';
import { AccentButton } from '../../../../components/MUI/buttons/button';
import BulletList from '../../../../components/Common/BulletList/BulletList';

export default function StudentJourney(props) {
  return (
    <Box className={classes.section} component='section'>
      <Container className={classes.container} maxWidth='lg'>
        <Typography className={classes.title} component='h2'>
          {props.title}
        </Typography>

        <Box className={classes.listBox}>
          <Box className={classes.progressBarBox}>
            <Box className={classes.progressBar}>
              <Box className={classes.line}></Box>
              <Box className={classes.points}>
                {[1, 2, 3, 4].map((_, index, arr) => (
                  <React.Fragment key={index}>
                    {index === arr.length - 1 ? (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                      >
                        <circle
                          cx='12'
                          cy='12.7056'
                          r='10'
                          fill='#8788E5'
                          stroke='#8788E5'
                          strokeWidth='4'
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                      >
                        <circle
                          cx='12'
                          cy='12.7056'
                          r='10'
                          fill='#F5F5FD'
                          stroke='#8788E5'
                          strokeWidth='4'
                        />
                        <circle
                          cx='12'
                          cy='12.7056'
                          r='4'
                          fill='#3D40D5'
                          fillOpacity='0.6'
                        />
                      </svg>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </Box>

          <Box className={classes.list}>
            {props.items.map((item, index) => (
              <Box className={classes.card} key={index}>
                <Box
                  className={`${classes.indexText} ${
                    classes[`item${index + 1}`]
                  }`}
                >
                  0{index + 1}
                </Box>
                <Box className={classes.cardInfo}>
                  <Typography className={classes.itemTitle} component='h3'>
                    {item.title}
                  </Typography>
                  <BulletList items={item.list} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <AccentButton
          isBig={false}
          component={Link}
          to={props.link.link}
          className={classes.ctaButton}
        >
          {props.link.text}
        </AccentButton>
      </Container>
    </Box>
  );
}
