import qs from 'qs';

export const getEventQueryBySlug = (slug) =>
  qs.stringify({
    filters: {
      slug: { $eq: slug },
    },
    populate: {
      sections: {
        on: {
          'event.hero': {
            populate: '*',
          },
          'event.for-who': {
            populate: {
              image: true,
              list: {
                populate: '*',
              },
            },
          },
          'event.meet': {
            populate: {
              image: true,
            },
          },
          'event.students-feedbacks': {
            populate: '*',
          },
          'event.why-sign-up': {
            populate: {
              link: true,
              items: {
                populate: '*',
              },
            },
          },
          'event.whats-next': {
            populate: {
              image: true,
              items: {
                populate: '*',
              },
            },
          },
          'event.post-webinar': {
            populate: {
              image: true,
              items: {
                populate: '*',
              },
            },
          },
          'event.watch': {
            populate: '*',
          },
        },
      },
    },
  });
