import React from "react";
import classes from "./index.module.scss";
import { Box, Container } from "@mui/material";
import { WhiteButton } from "../../../../components/MUI/buttons/button";

export default function WebinarBanner(props) {
  if (!props) return null;
  return (
    <Box className={classes.section} component="section">
      <Container maxWidth="lg">
        <div className={classes.content}>
          <div className={classes.block}>
            <div className={classes.header}>
              <div className={classes.tag}>{props.tag}</div>
              <div className={classes.date}>{props.date}</div>
            </div>
            <h3 className={classes.title}>
              <span>{props.title}</span>
              <span className={classes.dot}>.</span>{" "}
              <a target="_blank" href={props.button.link} rel="noreferrer">
                {props.button.text}
              </a>
            </h3>
          </div>
          <WhiteButton
            isBig={false}
            variant="contained"
            component={"a"}
            target="_blank"
            href={props.button.link}
            className={classes.button}
          >
            {props.button.text}
          </WhiteButton>
        </div>
      </Container>
    </Box>
  );
}
