import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import SectionCard from '../../components/Common/SectionCard';
import { AccentButton, RedButton } from '../../components/MUI/buttons/button';
import ShowPasswordIcon from '../../components/Common/ShowPasswordIcon';
import { Input } from '../../components/MUI/inputs';
import classes from './index.module.scss'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import useShowPassword from '../../hooks/useShowPassword';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../instances/axiosInstances';
import successToast from '../../components/toasts/successToast';
import errorToast from '../../components/toasts/errorToast';
import useModal from '../../components/Common/Modal/useModal';
import Modal from '../../components/Common/Modal';
import DeleteProfileModal from './DeleteProfileModal';
import { updatePasswordSchema } from './schema';
import SettingsLayout from '../../components/Layouts/SettingsLayout';

export default function Security() {
  const modalRef = useRef();
  const { handleOpen, handleClose } = useModal(modalRef);

  const { handleClickShowPassword, showPassword } = useShowPassword();
  const password = useRef({});

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  password.current = watch("password", "");

  const { mutate, isLoading } = useMutation(
    (data) => api.put("/users/password", data),
    {
      onSuccess: ({ data }) => {
        // setAuthUser(data);
        successToast("Password updated successfully");
        reset();
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    delete data.confirmPassword;
    mutate(data);
  };

  return (
    <>
      <Modal modalRef={modalRef} className={classes.modal}>
        <DeleteProfileModal handleClose={handleClose} />
      </Modal>

      <SettingsLayout title='Profile'>
        <SectionCard title='Change password'>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.password}>
              <Input
                label="Current password*"
                type={showPassword ? "text" : "password"}
                margin="normal"
                variant="outlined"
                fullWidth
                autoComplete="new-password"
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        <ShowPasswordIcon showPassword={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                placeholder="Current password"
                {...register("currentPassword")}
                error={!!errors.currentPassword}
                helperText={
                  errors.currentPassword && errors.currentPassword.message
                }
              />
              <Input
                label="New password*"
                type={showPassword ? "text" : "password"}
                margin="normal"
                variant="outlined"
                fullWidth
                autoComplete="off"
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        <ShowPasswordIcon showPassword={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                placeholder="New password"
                {...register("newPassword")}
                error={!!errors.newPassword}
                helperText={errors.newPassword && errors.newPassword.message}
              />
              <Input
                label="Repeat new password*"
                type={showPassword ? "text" : "password"}
                margin="normal"
                variant="outlined"
                fullWidth
                autoComplete="off"
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        <ShowPasswordIcon showPassword={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                placeholder="Repeat new password"
                {...register("confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword && errors.confirmPassword.message
                }
              />
            </Box>
            <Box className={classes.actions}>
              <AccentButton
                isBig={false}
                variant="contained"
                fullWidth
                type={"submit"}
                disabled={isLoading}
              >
                Update password
              </AccentButton>
            </Box>
          </Box>
        </SectionCard>
        
        <SectionCard title='Delete account'>
          <Box className={classes.delete}>
            <Typography className={classes.delete__text}>
              All your personal data, purchases, access to courses, rating and
              progress will be permanently deleted. This action can’t be
              reversed.
            </Typography>
            <Box className={classes.actions}>
              <RedButton
                onClick={handleOpen}
                isBig={false}
                variant="contained"
                fullWidth
              >
                Delete account
              </RedButton>
            </Box>
          </Box>
        </SectionCard>
      </SettingsLayout>
    </>
  )
}