import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";

import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../MUI/buttons/button";
import successToast from "../../../toasts/successToast";
import errorToast from "../../../toasts/errorToast";
import { useCancelSubscriptionApi } from "../../../../apis/payments";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

export default function CancelSubscriptionModal({
  handleCancelPopupClose,
}) {
  const queryClient = useQueryClient();
  const successToastId = "success-toast";
  const errorToastId = "error-toast";
  const cancelSubscriptionApi = useCancelSubscriptionApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["currentSubscription"],
        refetchType: "all",
      });
      handleCancelPopupClose();
      successToast(
        "Subscription canceled. Plan active until next billing period",
        {
          toastId: successToastId,
        }
      );
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message, {
        toastId: errorToastId,
        autoClose: false,
        hideProgressBar: true,
      });
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader
        title={"Cancel subscription?"}
        handleClose={handleCancelPopupClose}
      />
      <Typography className={classes.text}>
        Downgrade to Starter (Free) plan changes will be applied starting next
        billing period. Until then, you can continue using current plan
        features.You are eligible for a refund during 14-day period from the
        first transaction. Please check{" "}
        <Link
          to={"/terms-and-conditions"}
        >
          Terms&Conditions
        </Link>.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={handleCancelPopupClose}
          isBig={false}
          variant="contained"
          disabled={cancelSubscriptionApi.isLoading}
        >
          Close
        </WhiteButton>
        <RedButton
          onClick={() => cancelSubscriptionApi.mutate()}
          isBig={false}
          variant="contained"
          disabled={cancelSubscriptionApi.isLoading}
        >
          Yes, cancel
        </RedButton>
      </Box>
    </Box>
  );
}
