import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import classes from './WeeklyYouTube.module.scss';
import { Box, Container, Typography } from '@mui/material';
import { ModalContext } from '../../../../context/ModalContext';
import VideoModal from '../../../../components/Common/EmbedVideos/VideoWrapper';
import Carousel from './Carousel';

export default function WeeklyYoutube(props) {
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=3&playlistId=${props.playlistId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;

  const youtubeVideos = useQuery(['weeklyYouTube'], async () => {
    const response = await fetch(url);
    return response.json();
  });

  const { handleModalOpen } = useContext(ModalContext);

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  if (!youtubeVideos.data) return null;

  const items = youtubeVideos.data.items.map((item) => {
    const id = item.id;
    const description = item.snippet.description;
    const videoId = item.contentDetails.videoId;
    // const duration = durations[index];
    const thumbnail = {
      url: item.snippet.thumbnails.high.url,
      width: item.snippet.thumbnails.high.width,
      height: item.snippet.thumbnails.high.height,
    };
    const title = item.snippet.title;

    return {
      id,
      videoId,
      thumbnail,
      title,
      // duration,
      description,
    };
  });

  if (!items.length) return null;

  return (
    <Box className={classes.section} component="section">
      <Container className={classes.container} maxWidth="lg">
        <Box className={classes.box}>
          <Typography className={classes.title} component="h2">
            {props.title}
          </Typography>
          <Typography className={classes.description} component="p">
            {props.description}
          </Typography>
        </Box>
        <Carousel data={items} showModal={showModal} />
        <Box className={classes.box}>
          <a
            className={classes.ctaButton}
            href={props.link.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M21.5933 7.30848C21.4794 6.88588 21.2568 6.50048 20.9477 6.19064C20.6386 5.88081 20.2537 5.65734 19.8313 5.54248C18.2653 5.11248 12.0003 5.10548 12.0003 5.10548C12.0003 5.10548 5.73633 5.09848 4.16933 5.50948C3.74725 5.62962 3.36315 5.85625 3.0539 6.16761C2.74464 6.47897 2.52062 6.8646 2.40333 7.28748C1.99033 8.85348 1.98633 12.1015 1.98633 12.1015C1.98633 12.1015 1.98233 15.3655 2.39233 16.9155C2.62233 17.7725 3.29733 18.4495 4.15533 18.6805C5.73733 19.1105 11.9853 19.1175 11.9853 19.1175C11.9853 19.1175 18.2503 19.1245 19.8163 18.7145C20.2388 18.5998 20.6241 18.3769 20.934 18.0677C21.2439 17.7585 21.4677 17.3737 21.5833 16.9515C21.9973 15.3865 22.0003 12.1395 22.0003 12.1395C22.0003 12.1395 22.0203 8.87448 21.5933 7.30848ZM9.99633 15.1105L10.0013 9.11048L15.2083 12.1155L9.99633 15.1105Z"
                fill="white"
              />
            </svg>
            {props.link.text}
          </a>
        </Box>
      </Container>
    </Box>
  );
}
