import classes from "./index.module.scss";

export default function Loader({ type = "page" }) {
  return (
    <div
      className={`${classes.wrapper} ${
        type === "section" ? classes.section : ""
      } 
      ${type === "page" ? classes.page : ""}`}
    >
      <div className={classes.loader} />
    </div>
  );
}
