export const content = {
  popup1: {
    title: "Feeling like you’re ready for a challenge?",
    text: "We have an advanced-level course on this topic waiting for you!",
    btn1: "Yes, I’m ready!",
    btn2: "No, I’m good",
  },
  popup2: {
    title: "You're already halfway through this topic! ",
    text: "Why stop here? Unlock the full course and enhance your expertise.",
    btn1: "Subscribe now",
    btn2: "Maybe later",
  },
  popup3: {
    title: "Great job finishing the basic course!",
    text: "Take it to the next level and become a test automation expert with our advanced courses.",
    btn1: "Start Advanced Course",
    btn2: "Not now",
  },
};
