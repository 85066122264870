import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStripeSetupIntentApi } from '../../../apis/payments';

export default function PaymentProvider({ children, refreshTrigger }) {
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
  );

  const stripeSetupIntentApi = useStripeSetupIntentApi({
    onSuccess: ({ data }) => {
      setClientSecret(data.clientSecret);
    },
    onError: (error) => console.error(error),
  });

  const { mutate } = stripeSetupIntentApi || {};

  useEffect(() => {
    mutate();
    // Reset stripePromise dynamically
    setStripePromise(() =>
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
    );
  }, [refreshTrigger, mutate]);

  if (stripeSetupIntentApi.isLoading || !clientSecret) return null;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: 'flat',
        },
      }}
    >
      {children}
    </Elements>
  );
}
