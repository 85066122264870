import React from 'react';
import classes from './TextHighlight.module.scss';

export default function TextHighlight({
  text,
  height = '4px',
  bottom = '4px',
}) {
  const parseAndHighlight = (inputString) => {
    // Define a regex to find content inside square brackets
    const regex = /\[(.*?)\]/g;
    const elements = [];
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(inputString)) !== null) {
      const [fullMatch, content] = match;

      // Add text before the match
      if (match.index > lastIndex) {
        elements.push(inputString.substring(lastIndex, match.index));
      }

      // Add the highlighted content
      elements.push(
        <span
          key={match.index}
          className={classes.highlight}
          style={{
            '--highlight-height': height,
            '--highlight-bottom': bottom,
          }}
        >
          {content}
        </span>
      );

      lastIndex = regex.lastIndex;
    }

    // Add remaining text after the last match
    if (lastIndex < inputString.length) {
      elements.push(inputString.substring(lastIndex));
    }

    return elements;
  };

  const parsedText = parseAndHighlight(text);

  return <span>{parsedText}</span>;
}
