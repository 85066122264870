import axios from 'axios';

const options = {
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const api = axios.create(options);

export const apiFormData = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const apiCmsData = axios.create({
  baseURL: process.env.REACT_APP_WP_PATH,
});

export const apiCms = axios.create({
  baseURL: process.env.REACT_APP_CMS_API,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

apiFormData.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
