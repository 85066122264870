import React from "react";
import Marquee from "react-fast-marquee";
import classes from "./index.module.scss";

export default function TextMarquee(props) {
  if (!props) return null;
  return (
    <section className={classes.wrapper}>
      <Marquee autoFill className={classes.marquee}>
        {props.text.map((item, index) => (
          <div className={classes.item} key={`${item.id}-${index}`}>
            <div className={classes.item__text}>{item.text}</div>
            <div className={classes.item__icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="7"
                viewBox="0 0 6 7"
                fill="none"
              >
                <circle cx="3" cy="3.53198" r="3" fill="#A195EC" />
              </svg>
            </div>
          </div>
        ))}
      </Marquee>
    </section>
  );
}
