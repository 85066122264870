import * as yup from "yup";
import { PASSWORD_REGEX } from "../../../constants/regex";

export const schema = yup.object().shape({
  password: yup.string().when([], {
    is: () => {
      return !(
        JSON.parse(localStorage.getItem("user"))?.providerType ||
        JSON.parse(localStorage.getItem("sub"))
      );
    },
    then: () =>
      yup
        .string()
        .required("Password is required")
        .matches(
          PASSWORD_REGEX,
          "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
        ),
    otherwise: (schema) => schema.notRequired(),
  }),

  confirmPassword: yup.string().when([], {
    is: () =>
      !(
        JSON.parse(localStorage.getItem("user"))?.providerType ||
        JSON.parse(localStorage.getItem("sub"))
      ),
    then: () =>
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm password"),
    otherwise: (schema) => schema.notRequired(),
  }),

  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup
    .string()
    .min(10, "Phone Number is required")
    .required("Phone Number is required"),
  country: yup.string().required("Country is required"),
  city: yup.string().required("City is required"),
  state: yup.string().when("country", {
    is: (country) => country === "USA",
    then: () => yup.string().required("State is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
