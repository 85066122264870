import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { AuthContext } from "../../../context/AuthContext";
import Loader from "../../Common/Loader";
import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import classes from "./PaymentForm.module.scss";
import { AccentButton } from "../../MUI/buttons/button";
import { api } from "../../../instances/axiosInstances";
import errorToast from "../../toasts/errorToast";
import successToast from "../../toasts/successToast";
import { useNavigate } from "react-router-dom";

export default function PaymentForm({
  intentType = "payment",
  handleClose,
  selectedSubscription,
  courseRedirectPath,
  setCourseRedirectPath,
}) {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync } = useMutation(
    async ({ id }) =>
      await api.post("/subscriptions/stripe/prorate", {
        subscriptionPlanId: id,
      }),
    {
      staleTime: 0,
      onError: (e) => {
        errorToast(e.response.data.message);
      },
    }
  );

  const onSubscribeSuccess = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["plans"],
      refetchType: "all",
    });
    await queryClient.invalidateQueries({
      queryKey: ["currentSubscription"],
      refetchType: "all",
    });
    handleClose();
    setIsProcessing(false);
    successToast("Subscription updated successfully");
  };

  if (!stripe || !elements) return <Loader />;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsProcessing(true);

    try {
      if (intentType === "setup") {
        const response = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: "",
          },
          redirect: "if_required",
        });

        if (response.error) {
          console.log({ error: response.error });
          setIsProcessing(false);
          return;
        }
        await mutateAsync({ id: selectedSubscription.id });
        const timeout = setTimeout(() => {
          onSubscribeSuccess();
          courseRedirectPath && navigate(courseRedirectPath);
          courseRedirectPath && setCourseRedirectPath(undefined);
          clearTimeout(timeout);
        }, 1000);
      } else {
        const response = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: "",
          },
          redirect: "if_required",
        });

        if (response.error) {
          console.log({ error: response.error });
          setIsProcessing(false);
          return;
        }

        await onSubscribeSuccess();
        courseRedirectPath && navigate(courseRedirectPath);
        courseRedirectPath && setCourseRedirectPath(undefined);
      }
    } catch (error) {
      setIsProcessing(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classes.form}
    >
      {isProcessing ? <Loader type="section" /> : null}
      <PaymentElement
        options={{
          type: "tabs",
          defaultCollapsed: false,
          defaultValues: {
            billingDetails: {
              email: user?.email,
              name: user?.fullName,
            },
          },
          paymentMethodOrder: ["card", "google_pay", "apple_pay"],
        }}
      />
      <AccentButton
        disabled={isProcessing}
        isBig={false}
        variant="contained"
        fullWidth
        type="submit"
      >
        Pay
      </AccentButton>
    </form>
  );
}
