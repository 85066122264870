import {
  AccentButton,
  WhiteButton,
} from '../../../components/MUI/buttons/button';
import classes from './index.module.scss';
import { Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCancelDowngradeApi } from '../../../apis/payments';
import ModalHeader from '../../security/DeleteProfileModal/ModalHeader';
import successToast from '../../../components/toasts/successToast';
import errorToast from '../../../components/toasts/errorToast';

export default function CancelDowngradeModal({ handleClose }) {
  const queryClient = useQueryClient();

  const cancelDowngradeApi = useCancelDowngradeApi({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['currentSubscription'],
        refetchType: 'all',
      });
      handleClose();
      successToast('Downgrade canceled successfully');
    },
    onError: (error) => {
      errorToast(error?.response?.data?.message);
    },
  });

  return (
    <Box className={classes.wrapper}>
      <ModalHeader title='Cancel downgrade?' handleClose={handleClose} />
      <Typography className={classes.text}>
        No additional charges will be made. You will remain on your current
        subscription plan.
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          isBig={false}
          variant='contained'
          onClick={handleClose}
          disabled={cancelDowngradeApi.isLoading}
        >
          Close
        </WhiteButton>
        <AccentButton
          isBig={false}
          variant='contained'
          onClick={cancelDowngradeApi.mutate}
          disabled={cancelDowngradeApi.isLoading}
        >
          Yes, cancel
        </AccentButton>
      </Box>
    </Box>
  );
}
