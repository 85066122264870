import { useEffect, useState } from 'react';

export const useTimer = ({ pstDateString }) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(pstDateString));
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(pstDateString));
    }, 1000);

    return () => clearInterval(interval);
  }, [pstDateString]);
  function getTimeRemaining(pstDateString) {
    const localDate = new Date();
    const targetDate = new Date(createDateFromPDTString(pstDateString));

    const utcLocalDate = new Date(localDate.toLocaleString('en-US', { timeZone: 'UTC' }));

    const timeDifference = targetDate.getTime() - utcLocalDate.getTime();
    const seconds = timeDifference > 0 ? Math.floor((timeDifference / 1000) % 60) : 0;
    const minutes = timeDifference > 0 ? Math.floor((timeDifference / 1000 / 60) % 60) : 0;
    const hours = timeDifference > 0 ? Math.floor((timeDifference / (1000 * 60 * 60)) % 24) : 0;
    const days = timeDifference > 0 ? Math.floor(timeDifference / (1000 * 60 * 60 * 24)) : 0;

    return { days, hours, minutes, seconds };
  }
  return { timeRemaining };
};

function createDateFromPDTString(dateString) {
  const cleanDateString = dateString.replace(/^[A-Za-z]+, /, '').replace('(PDT)', '');

  const date = new Date(cleanDateString);

  const utcDate = new Date(date.getTime() + 7 * 60 * 60 * 1000);

  return utcDate;
}
