import { Link } from 'react-router-dom';
import classes from './index.module.scss';
import { Box, Typography } from '@mui/material';
import { formatDate, getTagNames } from './helpers';
import { ReactComponent as CircleIcon } from './images/circle.svg';
import { ReactComponent as CaldendarIcon } from './images/calendar.svg';

export default function PostCard({ data, variant = 'post', link = '' }) {
  const eventVariant = variant === 'event';

  const tagList = eventVariant ? data.tags : data?.post_tags;

  return (
    <Box className={classes.card}>
      <Box component={Link} className={classes.image} to={link}>
        {eventVariant ? (
          <img
            src={`${process.env.REACT_APP_CMS_DOMAIN}${data.image.url}`}
            width={data.image.formats.thumbnail.width}
            alt={data.title}
          />
        ) : (
          <img
            src={data.img_url?.full}
            alt={data.title?.rendered}
            width={data.img_url?.fullWidth}
          />
        )}
      </Box>
      <Typography className={classes.title} component={Link} to={link}>
        <span>{eventVariant ? data.title : data.title?.rendered}</span>
      </Typography>
      {eventVariant ? (
        <>
          <Box className={classes.eventDateBox}>
            <CaldendarIcon />
            {eventVariant ? (
              <>
                {data.date ? (
                  <Typography className={classes.eventDate}>
                    {data.date}
                  </Typography>
                ) : null}
              </>
            ) : (
              <>
                {data?.custom_fields?.date ? (
                  <Typography className={classes.eventDate}>
                    {data?.custom_fields?.date[0]}
                  </Typography>
                ) : null}
              </>
            )}
          </Box>
          <Typography className={classes.date}>
            {getTagNames(tagList, <CircleIcon />)}
          </Typography>
        </>
      ) : (
        <Typography className={classes.date}>
          {formatDate(data.date)}
          <CircleIcon />
          {getTagNames(tagList)}
        </Typography>
      )}
      {eventVariant ? (
        <Box className={classes.excerpt}>{data.shortDescription}</Box>
      ) : (
        <Box
          className={classes.excerpt}
          dangerouslySetInnerHTML={{
            __html: data.excerpt.rendered,
          }}
        />
      )}
    </Box>
  );
}
