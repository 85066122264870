import React from 'react'
import { calculatePercentageDifference } from '../../../helpers/calculatePercentageDifference';
import classes from "./RadioLabel.module.scss";
import PlanDiscount from '../../Common/PlanDiscount';
import { formatCurrency } from '../../../helpers/formatCurrency';

export default function RadioLabel({ originalPrice, price, name, period }) {
  const discount = originalPrice
    ? calculatePercentageDifference(originalPrice, price)
    : 0;
  return (
    <div className={classes.label}>
      <div className={classes.label__name}>
        <p>{name}</p>
        {discount ? <PlanDiscount>Save {discount}%</PlanDiscount> : null}
      </div>
      <p className={classes.label__price}>
        {formatCurrency(price)}/{period}
      </p>
    </div>
  );
}
