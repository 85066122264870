import { Rating } from "../../../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VideoModal from "../../../../../components/Common/EmbedVideos/VideoWrapper";
import React, { useContext } from "react";
import { ModalContext } from "../../../../../context/ModalContext";
import { AuthContext } from "../../../../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { Chip as CourseType } from "./Chip";
import Progress from "../../../../../components/Common/Progress/Progress";
// import CouponDetails from "../../../../../components/modal/BuyCourseModal/CouponDetails";
import {
  AccentButton,
  WhiteButton,
} from "../../../../../components/MUI/buttons/button";
import Card from "../Card";
import CardContent from "../CardContent";
import CardHeader from "../CardHeader";
import CardSubtitle from "../CardSubtitle";
import CardTitle from "../CardTitle";
import CardDescription from "../CardDescription";
import CardActions from "../CardActions";
import CardMedia from "../CardMedia";
import CardTags from "../CardTags";
import useMixpanel from "../../../../../hooks/useMixpanel";
import { mixpanelCourseCardRecorded } from "../../../../../constants/mixpanel";
import DifficultyLevel from "../DifficultyLevel";
import RecordedEnded from "../RecordedEnded";
import { Typography } from "@mui/material";
import { getLecturesCount } from "../../../../../helpers/formatTime";
import ScrollToAnchor from "../../../../../components/Common/ScrollToAnchor";
import { SubscriptionsContext } from "../../../../../context/SubscriptionsContext";

export default function CourseCardRecorded({ data, feedbacks }) {
  const { auth } = React.useContext(AuthContext);
  const { setCourseRedirectPath, setSelectedCourseId } =
    useContext(SubscriptionsContext);
  const { handleModalOpen } = useContext(ModalContext);
  const { pathname } = useLocation();
  const { handleEventTrack } = useMixpanel();
  const {
    buyForLink,
    startFreeCourseLink,
    startForFreeLink,
    continueLink,
  } = mixpanelCourseCardRecorded;

  const showVideoModal = ({ title, url }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  const isComplete = data?.isComplete;
  const isFeedbackComplete = data?.isFeedbackComplete;
  const certificate = data?.certificate;
  const instructors = data?.instructors;
  const courseId = data?.id;
  const type = data?.type;
  const difficultyLevel = data?.difficultyLevel;
  const paths = data?.paths;
  const title = data?.title;
  const promoImageUrl = data?.promoImageUrl;
  const promoVideoUrl = data?.promoVideoUrl;
  const currentLectureId = data?.currentLectureId;
  const coupon = data?.coupon;
  const tags = data?.tags;
  const shortDescription = data?.shortDescription;
  const isPaid = data?.isPaid;
  const lecturePoints = data?.lecturePoints;
  const modules = data?.modules;
  const isFree = data?.isFree;
  const coursePoints =
    modules && lecturePoints ? getLecturesCount(modules) * lecturePoints : 0;

  const rating = feedbacks?.length
    ? feedbacks.reduce((acc, feedback) => {
        acc = acc + Number(feedback.rating);
        return Number(acc);
      }, 0) / feedbacks.length
    : 0;

  const options = {
    rating: rating,
    votes: feedbacks?.length || 0,
    stars: rating,
    readOnly: true,
  };

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        {coursePoints || difficultyLevel || type ? (
          <CardHeader
            avatar={
              <>
                {type ? <CourseType aria-label="course type" /> : null}
                {difficultyLevel || difficultyLevel === 0 ? (
                  <DifficultyLevel level={difficultyLevel} />
                ) : null}
                {coursePoints ? (
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 700,
                      color: "var(--on-surface-b-090)",
                    }}
                  >
                    💎 {coursePoints}
                  </Typography>
                ) : null}
              </>
            }
          />
        ) : null}
        {rating ? (
          <ScrollToAnchor anchor={"reviews"}>
            <Rating options={options} aria-label="course rating" />
          </ScrollToAnchor>
        ) : null}
        {paths && paths.length ? <CardSubtitle paths={paths} /> : null}
        {title ? <CardTitle title={title} /> : null}
        {shortDescription ? (
          <CardDescription description={shortDescription} />
        ) : null}
        {tags && tags.length ? <CardTags tags={tags} /> : null}
        {/* {isFree && coupon ? (
          <CouponDetails
            coupon={coupon}
            discountedPrice={discountedPrice}
            defaultPrice={price}
            isPriceVisible={true}
          />
        ) : null} */}
        {isComplete ? (
          <RecordedEnded
            courseId={courseId}
            isFeedbackComplete={isFeedbackComplete}
            certificate={certificate}
            instructors={instructors}
            isPaid={isPaid}
          />
        ) : (
          <CardActions>
            {!isPaid && isFree ? (
              <AccentButton
                isBig={false}
                variant="contained"
                endIcon={<ChevronRightIcon />}
                component={Link}
                to={`/courses/${courseId}/lecture/preparation-tasks`}
                onClick={(e) => {
                  handleEventTrack(e, {
                    ...startFreeCourseLink,
                    options: {
                      ...startFreeCourseLink.options,
                      course: title,
                      courseId,
                    },
                  });
                }}
              >
                Start Free Course
              </AccentButton>
            ) : null}
            {!isPaid && !isFree ? (
              <>
                <AccentButton
                  isBig={false}
                  variant="contained"
                  component={Link}
                  to={"/subscriptions"}
                  onClick={(e) => {
                    handleEventTrack(e, {
                      ...buyForLink,
                      options: {
                        ...buyForLink.options,
                        course: title,
                        courseId,
                      },
                    });
                    setCourseRedirectPath(pathname);
                    setSelectedCourseId(courseId);
                  }}
                >
                  Get full access
                </AccentButton>
                <WhiteButton
                  isBig={false}
                  variant="outlined"
                  endIcon={<ChevronRightIcon />}
                  component={Link}
                  to={`/courses/${courseId}/lecture/preparation-tasks`}
                  onClick={(e) => {
                    handleEventTrack(e, {
                      ...startForFreeLink,
                      options: {
                        ...startForFreeLink.options,
                        course: title,
                        courseId,
                      },
                    });
                  }}
                >
                  Start for FREE
                </WhiteButton>
              </>
            ) : null}

            {auth && isPaid ? (
              <>
                <AccentButton
                  isBig={false}
                  variant={"contained"}
                  endIcon={<ChevronRightIcon />}
                  component={Link}
                  to={`/courses/${courseId}/lecture/${
                    currentLectureId ? currentLectureId : "preparation-tasks"
                  }`}
                  onClick={(e) => {
                    handleEventTrack(e, {
                      ...continueLink,
                      options: {
                        ...continueLink.options,
                        course: title,
                        courseId,
                      },
                    });
                  }}
                >
                  {isComplete ? "Review" : "Continue"}
                </AccentButton>
                <Progress course={data} />
              </>
            ) : null}
          </CardActions>
        )}
      </CardContent>

      <CardMedia
        promoImageUrl={promoImageUrl}
        title={title}
        promoVideoUrl={promoVideoUrl}
        showVideoModal={showVideoModal}
      />
    </Card>
  );
}
