import { Box, Typography } from "@mui/material";
import ModalHeader from "./ModalHeader";
import classes from "./index.module.scss";
import { RedButton, WhiteButton } from "../../../MUI/buttons/button";
import { Link } from "react-router-dom";

export default function DowngradeSubscriptionModal({ cancelDowngradePopup,
approveDowngradePopup }) {

  return (
    <Box className={classes.wrapper}>
      <ModalHeader title={"Change plan?"} handleClose={cancelDowngradePopup} />
      <Typography className={classes.text}>
        Downgrade changes will be applied starting next billing period. Until
        then, you can continue using current plan features. Plan information can
        be viewed in your{" "}
        <Link className={classes.link} to={"/profile"}>
          Profile settings
        </Link>
        .
      </Typography>
      <Box className={classes.actions}>
        <WhiteButton
          onClick={cancelDowngradePopup}
          isBig={false}
          variant="contained"
        >
          Close
        </WhiteButton>
        <RedButton
          onClick={approveDowngradePopup}
          isBig={false}
          variant="contained"
        >
          Yes, cancel
        </RedButton>
      </Box>
    </Box>
  );
}
