import { Link } from 'react-router-dom';
import classes from './Navbar.module.scss';

export default function Navbar({ className = '', variant }) {
  return (
    <nav className={`${classes.root} ${className}`}>
      <Link className={`${classes.link} ${classes[variant]}`} to='/courses'>
        Courses
      </Link>
      <Link className={`${classes.link} ${classes[variant]}`} to='/events'>
        Events
      </Link>
      <Link
        className={`${classes.link} ${classes[variant]}`}
        to='/subscriptions'
      >
        Pricing
      </Link>
    </nav>
  );
}
