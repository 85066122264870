import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import classes from "./RadioBtn.module.scss";
export default function RadioBtn({ children, value, disabled = false }) {
  return (
    <FormControlLabel
      className={classes.button}
      value={value}
      control={
        <Radio
          disableRipple
          disabled={disabled}
          sx={{
            "& .MuiSvgIcon-root": {
              color: "var(--colors-a-020)",
              fontSize: "16px",
            },
            "&.Mui-checked .MuiSvgIcon-root": {
              color: "var(--colors-a-100)",
            },
          }}
        />
      }
      label={children}
    />
  );
}
