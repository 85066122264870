import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VideoModal from "../../../../../components/Common/EmbedVideos/VideoWrapper";
import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../../../../context/ModalContext";
import { AuthContext } from "../../../../../context/AuthContext";
import { Chip as CourseType } from "./Chip";
import { formatDateTime } from "../../../../../helpers/formatDateTime";
import icon1 from "../../../../../assets/images/zondicons_time.svg";
import icon2 from "../../../../../assets/images/mdi_timer-sand-complete.svg";
import icon3 from "../../../../../assets/images/carbon_user-speaker.svg";
import { ReactComponent as LinkedinIcon } from "../../../../../assets/images/devicon_linkedin.svg";
import { AccentButton } from "../../../../../components/MUI/buttons/button";
import { Link, useLocation } from "react-router-dom";
import useActivateFreeCourse from "../../../../../hooks/useMutations";
// import CouponDetails from "../../../../../components/modal/BuyCourseModal/CouponDetails";
import { formatTimeMilliseconds } from "../../../../../helpers/formatTime";
import Card from "../Card";
import CardContent from "../CardContent";
import CardHeader from "../CardHeader";
import CardSubtitle from "../CardSubtitle";
import CardTitle from "../CardTitle";
import CardDescription from "../CardDescription";
import classes from "./index.module.scss";
import CardActions from "../CardActions";
import CardMedia from "../CardMedia";
import CardTags from "../CardTags";
import useMixpanel from "../../../../../hooks/useMixpanel";
import { mixpanelCourseCardLive } from "../../../../../constants/mixpanel";
import DifficultyLevel from "../DifficultyLevel";
import LiveEnded from "../LiveEnded";
import { Rating } from "../../../../../components/MUI";
import ScrollToAnchor from "../../../../../components/Common/ScrollToAnchor";

export default function CourseCardLive({ data, feedbacks }) {
  const { auth, setIsPayment, setIsFreePayment, isFreePayment } =
    React.useContext(AuthContext);
  const { pathname } = useLocation();
  const { handleModalOpen } = useContext(ModalContext);
  const localstorageFreePayment = localStorage.getItem("isFreePayment");
  const { handleEventTrack } = useMixpanel();
  const {
    freeRegistrationButton,
    freeRegistrationLink,
    buyForButton,
    buyForLink,
    onlineLecturesAnchor,
  } = mixpanelCourseCardLive;

  const attendancePointsLimit = data?.attendancePointsLimit;
  const engagementPointsLimit = data?.engagementPointsLimit;
  const extraActivitiesPointsLimit = data?.extraActivitiesPointsLimit;
  const finishPoints = data?.finishPoints;
  const type = data?.type;
  const courseId = data?.id;
  const difficultyLevel = data?.difficultyLevel;
  const isPaid = data?.isPaid;
  const isComplete = data?.isComplete;
  const title = data?.title;
  const promoVideoUrl = data?.promoVideoUrl;
  const promoImageUrl = data?.promoImageUrl;
  const discountedPrice = data?.discountedPrice;
  const paths = data?.paths;
  const shortDescription = data?.shortDescription;
  const liveSchedule = data?.liveSchedule;
  const duration = data?.duration;
  const instructors = data?.instructors;
  const completeDate = data?.completeDate;
  const certificate = data?.certificate;
  const isFeedbackComplete = data?.isFeedbackComplete;
  const price = data?.price;
  const coupon = data?.coupon;
  const lecturesCount = data?.lecturesCount;
  const tags = data?.tags;
  const isFree = data?.isFree;

  const coursePoints =
    attendancePointsLimit +
    engagementPointsLimit +
    extraActivitiesPointsLimit +
    finishPoints;

  const rating = feedbacks?.length
    ? feedbacks.reduce((acc, feedback) => {
        acc = acc + Number(feedback.rating);
        return Number(acc);
      }, 0) / feedbacks.length
    : 0;

  const options = {
    rating: rating,
    votes: feedbacks?.length || 0,
    stars: rating,
    readOnly: true,
  };

  const {
    mutate: activateFreeCourseMutation,
    isLoading: activateFreeCourseLoading,
  } = useActivateFreeCourse({
    id: courseId,
    title,
  });

  const showVideoModal = ({ title, url }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  const handleActivateFreeCourse = (id) => {
    let body = JSON.stringify({
      courseId: id,
    });

    activateFreeCourseMutation(body);
  };

  useEffect(() => {
    !activateFreeCourseLoading &&
      auth &&
      !!data &&
      !isPaid &&
      isFree &&
      (isFreePayment || localstorageFreePayment) &&
      handleActivateFreeCourse(courseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    data,
    isFreePayment,
    activateFreeCourseLoading,
    localstorageFreePayment,
  ]);

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        {type || coursePoints ? (
          <CardHeader
            avatar={
              <>
                {type ? <CourseType aria-label="course type" /> : null}
                {difficultyLevel || difficultyLevel === 0 ? (
                  <DifficultyLevel level={difficultyLevel} />
                ) : null}
                {coursePoints ? (
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 700,
                      color: "var(--on-surface-b-090)",
                    }}
                  >
                    💎 {coursePoints}
                  </Typography>
                ) : null}
              </>
            }
          />
        ) : null}
        {rating ? (
          <ScrollToAnchor anchor={"reviews"}>
            <Rating options={options} aria-label="course rating" />
          </ScrollToAnchor>
        ) : null}
        {paths && paths.length ? <CardSubtitle paths={paths} /> : null}
        {title ? <CardTitle title={title} /> : null}
        {shortDescription ? (
          <CardDescription description={shortDescription} />
        ) : null}
        <Box className={classes.info}>
          {!isComplete ? (
            <Typography className={classes.info__text}>
              <img src={icon1} alt="Start date" width={16} /> Start date:{" "}
              {formatDateTime(liveSchedule[0].from).formattedDate +
                ", " +
                formatDateTime(liveSchedule[0].from).time}
            </Typography>
          ) : null}
          <Typography className={classes.info__text}>
            <img src={icon2} alt="Duration" width={16} /> Duration:{" "}
            {lecturesCount} {lecturesCount === 1 ? "lecture" : "lectures"} (
            {formatTimeMilliseconds(duration).trim()})
          </Typography>

          <Typography className={classes.info__text}>
            <img src={icon3} alt="Instructors" width={16} /> Instructors:{" "}
            {instructors
              ? instructors.map((instructor, index, self) => {
                  return (
                    <React.Fragment key={instructor.id}>
                      {instructor?.linkedinLink ? (
                        <a
                          href={instructor.linkedinLink}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.info__link}
                        >
                          <LinkedinIcon />
                          {instructor.firstName + " " + instructor.lastName}
                        </a>
                      ) : (
                        instructor.firstName + " " + instructor.lastName
                      )}
                      {index === self.length - 1 ? null : ", "}
                    </React.Fragment>
                  );
                })
              : "No information"}
          </Typography>
        </Box>
        <Typography className={classes.support}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M12.8467 4.05332C11.96 3.63999 11 3.33999 10 3.16666C9.99123 3.16638 9.98251 3.16802 9.97444 3.17148C9.96638 3.17493 9.95917 3.18011 9.95333 3.18666C9.83333 3.40666 9.69333 3.69332 9.6 3.91332C8.53933 3.75332 7.46066 3.75332 6.4 3.91332C6.30666 3.68666 6.16666 3.40666 6.04 3.18666C6.03333 3.17332 6.01333 3.16666 5.99333 3.16666C4.99333 3.33999 4.04 3.63999 3.14666 4.05332C3.14 4.05332 3.13333 4.05999 3.12666 4.06666C1.31333 6.77999 0.81333 9.41999 1.06 12.0333C1.06 12.0467 1.06666 12.06 1.08 12.0667C2.28 12.9467 3.43333 13.48 4.57333 13.8333C4.59333 13.84 4.61333 13.8333 4.62 13.82C4.88666 13.4533 5.12666 13.0667 5.33333 12.66C5.34666 12.6333 5.33333 12.6067 5.30666 12.6C4.92666 12.4533 4.56666 12.28 4.21333 12.08C4.18666 12.0667 4.18666 12.0267 4.20666 12.0067C4.28 11.9533 4.35333 11.8933 4.42666 11.84C4.44 11.8267 4.46 11.8267 4.47333 11.8333C6.76666 12.88 9.24 12.88 11.5067 11.8333C11.52 11.8267 11.54 11.8267 11.5533 11.84C11.6267 11.9 11.7 11.9533 11.7733 12.0133C11.8 12.0333 11.8 12.0733 11.7667 12.0867C11.42 12.2933 11.0533 12.46 10.6733 12.6067C10.6467 12.6133 10.64 12.6467 10.6467 12.6667C10.86 13.0733 11.1 13.46 11.36 13.8267C11.38 13.8333 11.4 13.84 11.42 13.8333C12.5667 13.48 13.72 12.9467 14.92 12.0667C14.9333 12.06 14.94 12.0467 14.94 12.0333C15.2333 9.01332 14.4533 6.39332 12.8733 4.06666C12.8667 4.05999 12.86 4.05332 12.8467 4.05332ZM5.68 10.44C4.99333 10.44 4.42 9.80666 4.42 9.02666C4.42 8.24666 4.98 7.61332 5.68 7.61332C6.38666 7.61332 6.94666 8.25332 6.94 9.02666C6.94 9.80666 6.38 10.44 5.68 10.44ZM10.3267 10.44C9.64 10.44 9.06666 9.80666 9.06666 9.02666C9.06666 8.24666 9.62666 7.61332 10.3267 7.61332C11.0333 7.61332 11.5933 8.25332 11.5867 9.02666C11.5867 9.80666 11.0333 10.44 10.3267 10.44Z"
              fill="#3D40D5"
              fillOpacity="0.7"
            />
          </svg>
          Free support in Discord included in the course price
        </Typography>
        {tags && tags.length ? <CardTags tags={tags} /> : null}
        {/* {!isFree && discountedPrice && coupon ? (
          <CouponDetails
            coupon={coupon}
            discountedPrice={discountedPrice}
            defaultPrice={price}
            isPriceVisible={true}
          />
        ) : null} */}

        <LiveEnded
          courseId={courseId}
          isPaid={isPaid}
          isAuth={auth}
          isFree={isFree}
          pathname={pathname}
          isComplete={isComplete}
          isFeedbackComplete={isFeedbackComplete}
          certificate={certificate}
          completeDate={completeDate}
          instructors={instructors}
        />
        {!isComplete ? (
          <CardActions>
            {!isPaid && isFree ? (
              <>
                {auth ? (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    onClick={(e) => {
                      handleEventTrack(e, {
                        ...freeRegistrationButton,
                        options: {
                          ...freeRegistrationButton.options,
                          course: title,
                          courseId,
                        },
                      });
                      setIsFreePayment(true);
                      localStorage.setItem("isFreePayment", true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Free Registration
                  </AccentButton>
                ) : (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    component={Link}
                    to={"/registration"}
                    onClick={(e) => {
                      handleEventTrack(e, {
                        ...freeRegistrationLink,
                        options: {
                          ...freeRegistrationLink.options,
                          course: title,
                          courseId,
                        },
                      });
                      localStorage.setItem("redirectPath", pathname);
                      localStorage.setItem("isFreePayment", true);
                      setIsFreePayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Free Registration
                  </AccentButton>
                )}
              </>
            ) : null}
            {!isPaid && isFree ? (
              <>
                {auth ? (
                  <AccentButton
                    isBig={false}
                    variant={"contained"}
                    endIcon={<ChevronRightIcon />}
                    onClick={(e) => {
                      handleEventTrack(e, {
                        ...buyForButton,
                        options: {
                          ...buyForButton.options,
                          name: `Buy for $${price}`,
                          course: title,
                          courseId,
                        },
                      });
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Buy for ${discountedPrice ? discountedPrice : price}
                  </AccentButton>
                ) : (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    component={Link}
                    to={"/registration"}
                    onClick={(e) => {
                      handleEventTrack(e, {
                        ...buyForLink,
                        options: {
                          ...buyForLink.options,
                          name: `Buy for $${price}`,
                          course: title,
                          courseId,
                        },
                      });
                      localStorage.setItem("redirectPath", pathname);
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Buy for ${discountedPrice ? discountedPrice : price}
                  </AccentButton>
                )}
              </>
            ) : null}
            {auth && isPaid && !isComplete ? (
              <>
                <AccentButton
                  isBig={false}
                  variant={"contained"}
                  target="_blank"
                  component={"a"}
                  href={data?.courseLink}
                  onClick={(e) => {
                    handleEventTrack(e, {
                      ...onlineLecturesAnchor,
                      options: {
                        ...onlineLecturesAnchor.options,
                        course: title,
                        courseId,
                      },
                    });
                  }}
                >
                  Online lectures
                </AccentButton>
              </>
            ) : null}
          </CardActions>
        ) : null}
      </CardContent>
      <CardMedia
        promoImageUrl={promoImageUrl}
        title={title}
        promoVideoUrl={promoVideoUrl}
        showVideoModal={showVideoModal}
      />
    </Card>
  );
}
