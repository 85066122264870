import { useContext } from 'react';
import classes from './AuthButton.module.scss';
import useMixpanel from '../../../hooks/useMixpanel';
import useButtonClick from '../../../hooks/useButtonClick';
import { AuthContext } from '../../../context/AuthContext';
import { AccentButton, WhiteButton } from '../../MUI/buttons/button';
import { mixpanelLandingHeaderBurger } from '../../../constants/mixpanel';

export default function AuthButton({ variant = 'header', className = '' }) {
  const { auth } = useContext(AuthContext);

  const { onStartForFreeClick } = useButtonClick();
  const { handleEventTrack } = useMixpanel();

  const { signUpLogin } = mixpanelLandingHeaderBurger;

  if (auth) return null;

  return (
    <div className={`${classes.root} ${className}`}>
      {['header', 'landing', 'platform'].includes(variant) ? (
        <AccentButton
          isBig={false}
          variant='outlined'
          rel='noreferrer'
          onClick={(e) => {
            handleEventTrack(e, signUpLogin);
            onStartForFreeClick();
          }}
        >
          <span className={classes.mobText}>Sign In</span>
          <span className={classes.deskText}>Sign Up / Login</span>
        </AccentButton>
      ) : (
        <WhiteButton
          isBig={false}
          variant={variant === 'promo' ? 'promo': 'outlined'}
          rel='noreferrer'
          onClick={(e) => {
            handleEventTrack(e, signUpLogin);
            onStartForFreeClick();
          }}
        >
          Sign Up / Login
        </WhiteButton>
      )}
    </div>
  );
}
