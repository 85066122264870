import React from "react";
import classes from "./index.module.scss";
import { LandingLayout } from "../../../components/Layouts";
import { Container, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import Loader from "../../../components/Common/Loader";
import PlansList from "../../../components/Common/PlansList";
import BuySubscriptionModal from "../../../components/modal/BuySubscriptionModal";

export default function Plans({ isAuthLoading }) {
  
  const { data, isLoading, isError } = useQuery(
    ["plans"],
    () => api.get(`/subscriptions/plans?postStatus=published`),
    {
      enabled: true,
      staleTime: Infinity,
    }
  );

  const isDataLoading = isAuthLoading || isLoading;
  return (
    <LandingLayout>
      <BuySubscriptionModal plans={data} />
      <section className={classes.section}>
        <Container maxWidth="lg">
          {isDataLoading ? (
            <Loader />
          ) : isError ? (
            <Typography
              component={"h1"}
              variant={"p"}
              className={classes.helperText}
            >
              Something went wrong. Try refreshing the page.
            </Typography>
          ) : data.data.length ? (
            <>
              <Typography className={classes.title} component="h1">
                Plans
              </Typography>
              <p className={classes.text}>
                Invest in Your Future — Choose the Plan That Works for You
              </p>
              <PlansList list={data.data} />
            </>
          ) : (
            <Typography
              component={"h1"}
              variant={"p"}
              className={classes.helperText}
            >
              There are no plans available yet.
            </Typography>
          )}
        </Container>
      </section>
    </LandingLayout>
  );
}
